import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';

import {
  authReducer,
  categoriesReducer,
  homepageReducer,
  ingredientReducer,
  ingredientsGroupsReducer,
  ingredientsReducer,
  messageReducer,
  modalReducer,
  profileReducer,
  recipeReducer,
  recipesReducer,
  tagsReducer,
} from '../reducers';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    ingredient: ingredientReducer,
    ingredients: ingredientsReducer,
    ingredientsGroups: ingredientsGroupsReducer,
    message: messageReducer,
    modal: modalReducer,
    profile: profileReducer,
    recipe: recipeReducer,
    recipes: recipesReducer,
    homepage: homepageReducer,
    tags: tagsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
