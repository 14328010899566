import { RootState } from '../store/store';

export const selectIngredientsData = (state: RootState) =>
  state.ingredients.response.data;
export const selectIngredientsMetadata = (state: RootState) =>
  state.ingredients.response.metadata;
export const selectIngredientsActiveAlphabet = (state: RootState) =>
  state.ingredients.activeAlphabet;
export const selectIngredientsQuery = (state: RootState) =>
  state.ingredients.query;
