import throttle from 'lodash/throttle';

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';

import { fetchTags } from '../../../reducers/tagsReducer';
import { selectTags } from '../../../selectors/tagsSelectors';
import { RootState, useAppDispatch } from '../../../store/store';

const Wrapper = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
`;

const Title = styled.p``;

const SelectTags: React.FC<{
  formState: any;
  setFormState: (value: any) => void;
}> = ({ formState, setFormState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const options = useSelector((state: RootState) => selectTags(state));
  const loading = isOpen && options.length === 0;
  const dispatch = useAppDispatch();

  const fetch = useMemo(
    () =>
      throttle(async (request: { input: string }) => {
        dispatch(fetchTags({ phrase: request.input }));
      }, 500),
    [],
  );

  useEffect(() => {
    fetch({ input: inputValue });
  }, [inputValue, fetch]);

  const createNewTag = (newTag: string) => {
    return (
      newTag &&
      setFormState({
        ...formState,
        tags: [...formState.tags, newTag],
      })
    );
  };

  const addAdditionalTag = (newTag?: string) => {
    return (
      newTag &&
      setFormState({
        ...formState,
        tags: [...formState.tags, newTag],
      })
    );
  };

  const removeTag = (newTags: string[]) =>
    setFormState({
      ...formState,
      tags: newTags,
    });

  return (
    <Wrapper>
      <Title>List of tags</Title>
      <FormControl fullWidth>
        <Autocomplete
          freeSolo
          multiple
          disableCloseOnSelect
          open={isOpen}
          onChange={(e, newValue: any[], reason) => {
            if (reason === 'createOption') {
              createNewTag(newValue[newValue.length - 1]);
            }
            if (reason === 'selectOption') {
              addAdditionalTag(newValue[newValue.length - 1]);
            }
            if (reason === 'removeOption') {
              removeTag(newValue);
            }
          }}
          value={formState.tags}
          filterOptions={(x) => x}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          isOptionEqualToValue={(option, value) => option === value}
          // @ts-ignore
          getOptionLabel={(option) => option}
          options={options}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          loading={loading}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Wrapper>
  );
};

export default SelectTags;
