import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Pagination } from '../../components';
import NoResults from '../../components/NoResults';
import { errorDefault } from '../../constants/defaultTypes';
import { getCategories } from '../../reducers/categoriesReducer';
import {
  selectCategoriesData,
  selectCategoriesMetadata,
} from '../../selectors/categoriesSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { ICategory, IError } from '../../types';
import Category from './components/Category';

const CategoriesContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CategoriesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useSelector((state: RootState) => selectCategoriesData(state));
  const metadata = useSelector((state: RootState) =>
    selectCategoriesMetadata(state),
  );
  const [page, setPage] = useState(metadata.page);
  const [shouldFetchData, triggerDateRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>(errorDefault);

  const handleGetCategories = () => {
    setLoading(true);
    dispatch(getCategories(page))
      .unwrap()
      .catch((e: IError) => {
        console.log({ e });
        setError(e);
      });
    setLoading(false);
  };

  useEffect(() => {
    handleGetCategories();
  }, [page, shouldFetchData]);

  return (
    <CategoriesContainerDiv>
      {!loading && (
        <>
          {error.message && !data && <div>{error.message}</div>}
          {data.length > 0 && (
            <>
              {data.map((category: ICategory, i: number) => (
                <Category
                  key={i}
                  category={category}
                  triggerDateRefresh={triggerDateRefresh}
                />
              ))}
              <Pagination
                page={metadata.page}
                pagesCount={metadata.pagesCount}
                setPage={setPage}
              />
            </>
          )}
          {!loading && data && data.length === 0 && !error.message && (
            <NoResults />
          )}
        </>
      )}
    </CategoriesContainerDiv>
  );
};

export default CategoriesContainer;
