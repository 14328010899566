import React, { ChangeEvent } from 'react';

import { FormControl, TextField } from '@mui/material';

const TextInput: React.FC<{
  label: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string | number;
  type?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  required?: boolean;
  disabled?: boolean;
  minValue?: number;
  step?: number;
}> = ({
  label,
  onChange,
  value,
  type = 'text',
  variant = 'standard',
  required = false,
  disabled = false,
  minValue,
  step = 1,
  onFocus,
  onBlur,
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        required={required}
        variant={variant}
        value={value}
        onChange={onChange}
        type={type}
        label={label}
        disabled={disabled}
        InputProps={{ inputProps: { min: minValue, step: step } }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </FormControl>
  );
};

export default TextInput;
