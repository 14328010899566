import React from 'react';
import styled from 'styled-components';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';

import { EIngredientType } from '../../../constants/enums';
import { SPACING_2, createSize } from '../../../foundations';
import { ICaloriesValueComponent } from '../types';

const SpanWrapper = styled.span`
  padding-right: ${SPACING_2};
`;

const TypeValue = styled.div`
  display: flex;
  align-items: center;
`;

const CaloriesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: ${createSize(45)};

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const CaloriesValue: React.FC<ICaloriesValueComponent> = ({
  caloriesValue: { type, caloriesPerHundred, enabled },
  editable,
  formState,
  updateCalories,
  toggleEnabled,
}) => {
  const formCaloriesValue = formState?.caloriesMap.find((e) => e.type === type);
  const typeName = (type: EIngredientType) => {
    switch (type) {
      case EIngredientType.WEIGHT:
        return '100g';
      case EIngredientType.QUANTITY:
        return '1 piece';
      default:
        return '100ml';
    }
  };
  if (
    updateCalories === undefined ||
    formState === undefined ||
    formCaloriesValue === undefined
  ) {
    return (
      <CaloriesWrapper>
        <TypeValue>
          Calories (per {typeName(type)}): {caloriesPerHundred} kcal
        </TypeValue>
        {editable && (
          <>
            <FormControl
              component="fieldset"
              required
              fullWidth
              sx={{ mr: 3, width: 'unset' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!editable}
                    defaultChecked={enabled !== null}
                  />
                }
                label="Use Measurement"
              />
            </FormControl>
          </>
        )}
      </CaloriesWrapper>
    );
  }

  return (
    <FormControl component="fieldset" required fullWidth sx={{ mr: 3, mb: 2 }}>
      <CaloriesWrapper>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!editable}
              defaultChecked={enabled}
              onChange={() => {
                toggleEnabled && toggleEnabled();
              }}
            />
          }
          label=""
        />
        <TypeValue>
          <SpanWrapper>
            Calories (per {typeName(formCaloriesValue.type)}):
          </SpanWrapper>
          <TextField
            required
            id="outlined-basic"
            variant="standard"
            disabled={!enabled}
            label=""
            defaultValue={formCaloriesValue.caloriesPerHundred || 0}
            type="number"
            onChange={(e) => {
              if (updateCalories !== undefined && formState !== undefined) {
                updateCalories(Number.parseInt(e.target.value));
              }
            }}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </TypeValue>
      </CaloriesWrapper>
    </FormControl>
  );
};

export default CaloriesValue;
