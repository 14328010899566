import { v4 } from 'uuid';

import React from 'react';

import { IListElement, ITextChild } from '../../../containers/ViewRecipe/types';
import TextChild from './TextChild';

const ListItem: React.FC<{
  value: IListElement;
}> = ({ value }) => {
  return (
    <li>
      {value.children.map((textElement: ITextChild) => {
        return <TextChild key={v4()} value={textElement} />;
      })}
    </li>
  );
};

export default ListItem;
