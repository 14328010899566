import {
  IEditRecipe,
  IRecipeIngredient,
  IViewIngredient,
  IViewRecipe,
} from '../types';

export const adaptRecipeViewToEditState = (
  recipe: IViewRecipe,
): IEditRecipe => {
  return {
    title: recipe.title,
    source: recipe.source ?? '',
    categoryId: recipe.category.id,
    images: recipe.images.map((i) => i.original),
    // @ts-ignore
    instructions: JSON.parse(recipe.instructions),
    tags: recipe.tags,
    recipeIngredients: recipe.recipeIngredients,
    time: recipe.time,
    difficulty: recipe.difficulty,
    servings: recipe.servings,
  };
};

export const adaptIngredientViewToRecipeIngredient = (
  view: IViewIngredient,
): IRecipeIngredient => {
  return {
    amount: 0,
    group: '',
    ingredient: view.id,
    measurement: '',
    availableMeasurements: view.measurements,
    skipAmount: false,
    skipAmountDescription: '',
    preparationInstructions: '',
    name: view.name,
  };
};
