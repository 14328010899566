import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { TextField } from '@mui/material';

import Modal from '../../components/Modal/Modal';
import { errorDefault } from '../../constants/defaultTypes';
import { AddCategoryInitialState } from '../../constants/initialStates';
import { containerRoot } from '../../constants/modals';
import { SPACING_2 } from '../../foundations';
import { addCategory, getCategories } from '../../reducers/categoriesReducer';
import { closeModal } from '../../reducers/modalReducer';
import { useAppDispatch } from '../../store/store';
import { IAddCategory, IError } from '../../types';

const messageTimeout = 5000;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
`;

const InputWrapper = styled.div`
  padding: ${SPACING_2};
  width: 100%;
`;

const AddCategoryModalContainer: React.FC = () => {
  const [formState, setFormState] = useState<IAddCategory>(
    AddCategoryInitialState,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IError>(errorDefault);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const resetInputs = () => setFormState(AddCategoryInitialState);
  const setCloseModal = () => {
    dispatch(closeModal());
    resetInputs();
  };

  const handleSaveCategory = () => {
    setError(errorDefault);
    setSuccess(false);
    setLoading(true);
    dispatch(addCategory({ formState }))
      .unwrap()
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          dispatch(getCategories(1));
          setCloseModal();
        }, messageTimeout);
      })
      .catch((e: any) => {
        setError(e);
        setLoading(false);
      });
  };

  const isSaveDisabled = formState.name === '';
  return ReactDOM.createPortal(
    <Modal
      title="Add Category"
      onClose={() => setCloseModal()}
      onSave={(e) => {
        e.preventDefault();
        handleSaveCategory();
      }}
      disableSaveButton={loading || isSaveDisabled}
      disableCloseButton={loading}
      isLoading={loading}
      isError={!!error.message}
      errorMessage={error.message}
      isSuccess={success}
      additionalButtonText=""
      additionalButtonOnClick={() => {}}
      successMessage="Good job! Category was added successfully!"
    >
      <FormWrapper>
        <InputsWrapper>
          <InputWrapper>
            <TextField
              required
              error={error?.payload?.name}
              helperText={error?.payload?.name}
              id="outlined-basic"
              variant="standard"
              label="Name"
              value={formState.name}
              type="text"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                })
              }
            />
          </InputWrapper>
        </InputsWrapper>
      </FormWrapper>
    </Modal>,
    containerRoot,
  );
};

export default AddCategoryModalContainer;
