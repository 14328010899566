import { useSlate } from 'slate-react';

import React, { ReactComponentElement } from 'react';

import { TEXT_ALIGN_TYPES, isBlockActive, toggleBlock } from '../helpers';
import { Button } from './index';

const BlockButton: React.FC<{
  format: string;
  icon: ReactComponentElement<any>;
}> = ({ format, icon }) => {
  const editor = useSlate();
  const blockType = TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type';
  return (
    <Button
      active={isBlockActive(editor, format, blockType)}
      onMouseDown={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

export default BlockButton;
