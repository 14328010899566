import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';

import { homepageLink } from '../../constants/routes';
import {
  GROUP_4,
  SPACING_2,
  SPACING_4,
  SPACING_6,
  SPACING_15,
  createSize,
} from '../../foundations';
import { isValidEmail } from '../../helpers/auth';
import { login } from '../../reducers/authReducer';
import { selectIsLoggedIn } from '../../selectors/authSelectors';
import { RootState } from '../../store/store';
import { IAuthError, ILoginForm, authErrorDefault } from '../../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 97%;
`;

const Title = styled.h3`
  position: relative;
  font-size: 24px;
  font-weight: 700;
  padding: ${SPACING_4} 0;

  @media (min-width: ${GROUP_4}) {
    font-size: 36px;
  }
`;

const ErrorWrapper = styled.div`
  position: absolute;
  bottom: -${SPACING_15};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${createSize(320)};
`;

const FormWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 ${SPACING_6};
  width: ${createSize(320)};
`;

const FormControlWrapper = styled.div`
  width: 100%;
  padding-bottom: ${SPACING_2};
`;

const Login: React.FC = () => {
  const initialFormState = {
    email: '',
    password: '',
  };

  const [formState, setFormState] = useState<ILoginForm>(initialFormState);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IAuthError>(authErrorDefault);
  const isLoggedIn = useSelector((state: RootState) => selectIsLoggedIn(state));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    const { email, password } = formState;
    setLoading(true);

    // @ts-ignore
    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        navigate(homepageLink);
      })
      .catch((e: IAuthError) => {
        console.log({ e });
        setError(e);
      });
    setLoading(false);
  };

  if (isLoggedIn) {
    return <Navigate to={homepageLink} />;
  }

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Wrapper>
      <Title>LOGIN PAGE</Title>
      <FormWrapper>
        <FormControlWrapper>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              label="Email"
              value={formState.email}
              type="email"
              onChange={handleChange('email')}
              error={!!error?.errors?.email}
              helperText={error?.errors?.email}
            />
          </FormControl>
        </FormControlWrapper>
        <FormControlWrapper>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              required
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={formState.password}
              onChange={handleChange('password')}
              error={!!error?.errors?.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {error?.errors?.password && (
            <FormHelperText error id="outlined-adornment-password">
              {error?.errors?.password}
            </FormHelperText>
          )}
        </FormControlWrapper>
        <Button
          variant="outlined"
          type="submit"
          disabled={
            !isValidEmail(formState.email) ||
            formState.password === '' ||
            loading
          }
          onClick={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          Login
        </Button>

        {error?.errors?.message && (
          <ErrorWrapper>
            <Alert severity="error">{error.errors.message}</Alert>
          </ErrorWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  );
};

export default Login;
