import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  apiIngredientIndexes,
  apiIngredientsBulk,
  apiIngredientsSearch,
} from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { successStatuses } from '../helpers/backendClient';
import {
  IIngredientsQueryParameters,
  IIngredientsResponse,
  IReducerState,
} from '../types';

const metadataInitialState = {
  page: 1,
  onPage: 0,
  pagesCount: 0,
  totalElements: 0,
};

const initialState: IReducerState = {
  activeAlphabet: [],
  response: {
    metadata: metadataInitialState,
    data: [],
  },
  query: {},
};

export const updateIngredientsQuery = createAsyncThunk<
  IIngredientsQueryParameters,
  IIngredientsQueryParameters
>('ingredients/updateQuery', (payload) => payload);

export const addIngredients = createAsyncThunk(
  'ingredients/add',
  // @ts-ignore
  async ({ formState }, thunkAPI) => {
    try {
      formState = formState.map((ingredient: any) => {
        ingredient.caloriesMap.map((element: any) => {
          element.caloriesPerHundred = element.enabled
            ? element.caloriesPerHundred
            : null;

          return element;
        });

        return ingredient;
      });
      const response = await fetch(apiIngredientsBulk, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ ingredients: formState }),
      });
      let data = await response.json();
      if (successStatuses.includes(response.status)) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data.errors);
      }
    } catch (error: any) {
      throw thunkAPI.rejectWithValue(error);
    }
  },
);

export const getIngredients = createAsyncThunk<
  IIngredientsResponse,
  IIngredientsQueryParameters
>('ingredients/get', async (queryParameters, thunkAPI) => {
  const apiIngredient = apiIngredientsSearch(queryParameters);
  try {
    const response: any = await fetch(apiIngredient, {
      method: 'GET',
      headers: getHeaders(),
    });
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data);
    }
  } catch (e: any) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getActiveAlphabet = createAsyncThunk(
  'ingredients/alphabet/get',
  async ({}, thunkAPI) => {
    try {
      const response: any = await fetch(apiIngredientIndexes, {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getIngredients.fulfilled}`]: (state, action) => {
      return { ...state, response: action.payload };
    },
    [`${getIngredients.rejected}`]: (state) => {
      return {
        ...state,
        response: { metadata: metadataInitialState, data: [] },
      };
    },
    [`${getActiveAlphabet.fulfilled}`]: (state, action) => {
      return { ...state, activeAlphabet: [...action.payload] };
    },
    [`${getActiveAlphabet.rejected}`]: (state) => {
      return { ...state, activeAlphabet: { ...initialState.activeAlphabet } };
    },
    [`${updateIngredientsQuery.fulfilled}`]: (state, action) => {
      return { ...state, query: action.payload };
    },
  },
});

export default ingredientsSlice.reducer;
