import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiIngredient } from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { IViewIngredient } from '../types';

const initialState: IViewIngredient = {
  id: '',
  name: '',
  caloriesMap: [],
  measurements: [],
};

export const getIngredient = createAsyncThunk<IViewIngredient, string>(
  'ingredient/get',
  // @ts-ignore
  async (id, thunkAPI) => {
    const apiGetIngredient = apiIngredient(id);
    try {
      const response: any = await fetch(apiGetIngredient, {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        data.caloriesMap = data.caloriesMap.map((e: any) => {
          return { ...e, enabled: e.caloriesPerHundred !== null };
        });
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deleteIngredient = createAsyncThunk(
  'ingredient/delete',
  // @ts-ignore
  async ({ id }, thunkAPI) => {
    try {
      const response: any = await fetch(apiIngredient(id), {
        method: 'DELETE',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateIngredient = createAsyncThunk(
  'ingredient/update',
  // @ts-ignore
  async ({ id, formState }, thunkAPI) => {
    try {
      formState.caloriesMap = formState.caloriesMap.map((i: any) => {
        return {
          type: i.type,
          caloriesPerHundred: i.enabled ? i.caloriesPerHundred : null,
        };
      });

      const response: any = await fetch(apiIngredient(id), {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(formState),
      });
      let data = await response.json();
      if (response.status === 200 || response.status === 202) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getIngredient.fulfilled}`]: (state, action) => {
      return { ...state, ...action.payload };
    },
    [`${getIngredient.rejected}`]: (state) => {
      return {
        ...state,
      };
    },
  },
});

export default ingredientSlice.reducer;
