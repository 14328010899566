const convertStringToNumber = (val: string) => parseInt(val, 10);

const createSize = (sizeInPixels: number | string) => {
  const size =
    typeof sizeInPixels === 'string'
      ? convertStringToNumber(sizeInPixels)
      : sizeInPixels;

  return `${size / 16}rem`;
};

export { createSize };
