export interface IAuthResponse {
  token: string;
  refreshToken: string;
}

export interface IUnauthResponse {
  status: number | undefined;
  message: string | undefined;
  errors?: IAuthErrorObject;
}

export interface ILoginForm {
  email: string;
  password: string;
}

export interface IAuthErrorObject {
  email?: string;
  password?: string;
  message?: string;
}

export interface IAuthError {
  errors: IAuthErrorObject;
}

export const authErrorDefault: IAuthError = {
  errors: {},
};

export interface JWTPayload {
  exp: number;
  sub: string;
  iat: number;
  roles: string[];
  username: string;
}
