import { RootState } from '../store/store';

export const selectAllGroups = (state: RootState) => state.ingredientsGroups;
export const selectIngredientsFromGroups = (state: RootState) => {
  return Object.keys(state.ingredientsGroups)
    .map((key: string) => {
      const group = state.ingredientsGroups[parseInt(key)];
      return Object.keys(group.ingredients).map((key: string) => {
        return { ...group.ingredients[key], group: group.name };
      });
    })
    .reduce(
      (previousValue, currentValue) => [...previousValue, ...currentValue],
      [],
    );
};
