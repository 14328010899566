import { hasAccessForRolesAndRequirement } from '../helpers/access';
import { RootState } from '../store/store';

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectExpiryDate = (state: RootState) => state.auth.expiryDate;
export const selectHasAccess = (state: RootState) => (requirement: string) => {
  // @ts-ignore
  if (state.auth.roles === []) {
    return false;
  }

  return hasAccessForRolesAndRequirement(state.auth.roles, requirement);
};
export const selectUserId = (state: RootState) => state.auth.userId;
