import React from 'react';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import { GROUP_2, GROUP_5, SPACING_5, createSize } from '../../foundations';

const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${createSize(56)};
  padding-bottom: ${SPACING_5};
  width: 100%;

  @media (min-width: ${GROUP_5}) {
    justify-content: flex-start;
  }
`;

const Title = styled.h1`
  font-size: x-large;

  @media (min-width: ${GROUP_2}) {
    font-size: xx-large;
  }

  @media (min-width: ${GROUP_5}) {
    font-size: x-large;
  }
`;

const CloseButton = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  right: 0;

  svg {
    transform: rotate(${({ isCollapsed }) => (isCollapsed ? -45 : 0)}deg);
    transition: all ease-in-out 0.25s;
  }

  @media (min-width: ${GROUP_5}) {
    display: none;
  }
`;

const Logo: React.FC<{
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}> = ({ isCollapsed, setIsCollapsed }) => {
  return (
    <LogoWrapper>
      <Title>Your Cookbook</Title>
      <CloseButton
        isCollapsed={isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <CloseIcon />
      </CloseButton>
    </LogoWrapper>
  );
};

export default Logo;
