import React from 'react';
import styled from 'styled-components';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { Card } from '../../../components';
import {
  SPACING_1,
  SPACING_2,
  SPACING_3,
  SPACING_4,
  SPACING_13,
} from '../../../foundations';
import { ICategory } from '../../../types';

const TextWrapper = styled.div`
  padding: ${SPACING_3} ${SPACING_2} ${SPACING_1} ${SPACING_2};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid #e1e2df;
`;

const IconButtonWrapper = styled(IconButton)`
  padding: 0 ${SPACING_2};
`;

const Image = styled.img`
  margin: ${SPACING_4} ${SPACING_2} ${SPACING_2};
  max-width: ${SPACING_13};
  z-index: 1;
`;

const UneditableCategory: React.FC<{
  category: ICategory;
  isAdmin: boolean;
  editCategory: () => void;
  deleteCategory: () => void;
}> = ({ category, isAdmin, editCategory, deleteCategory }) => {
  return (
    <Card
      buttons={
        isAdmin && (
          <ButtonsWrapper>
            <IconButtonWrapper aria-label="edit" onClick={editCategory}>
              <EditIcon />
            </IconButtonWrapper>
            <IconButtonWrapper aria-label="delete" onClick={deleteCategory}>
              <DeleteIcon />
            </IconButtonWrapper>
          </ButtonsWrapper>
        )
      }
    >
      <Image
        src={category.image.compressed || 'https://via.placeholder.com/150'}
        alt={`Category ${category.name}`}
      />
      <TextWrapper>{category.name}</TextWrapper>
    </Card>
  );
};

export default UneditableCategory;
