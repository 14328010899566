// @ts-ignore
import { v4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Container, TextField } from '@mui/material';

import { Loader } from '../../assets';
import { Button } from '../../components';
import { errorDefault } from '../../constants/defaultTypes';
import { ROLE_USER_ADMIN, ROLE_USER_EDIT } from '../../constants/roles';
import { SPACING_0, SPACING_1, SPACING_2, SPACING_3 } from '../../foundations';
import {
  deleteIngredient,
  getIngredient,
  updateIngredient,
} from '../../reducers/ingredientReducer';
import { getIngredients } from '../../reducers/ingredientsReducer';
import { selectHasAccess } from '../../selectors/authSelectors';
import { selectIngredientState } from '../../selectors/ingredientSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import {
  IAddIngredient,
  ICaloriesValue,
  IError,
  IViewIngredient,
} from '../../types';
import CaloriesValue from '../Ingredients/components/CaloriesValue';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${SPACING_3} ${SPACING_2} ${SPACING_1} ${SPACING_0};
  width: 90%;
`;

const SpanNameWrapper = styled.span`
  padding-right: ${SPACING_2};
`;

const ViewIngredient: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [formState, setFormState] = useState<IAddIngredient>({
    name: '',
    caloriesMap: [],
  });
  const [_, setError] = useState<IError>(errorDefault);
  const [loading, setLoading] = useState(true);
  const hasAccess = useSelector((state: RootState) => selectHasAccess(state));
  const isAdmin = hasAccess(ROLE_USER_ADMIN);
  const isUserEdit = hasAccess(ROLE_USER_EDIT);
  const id = location.pathname.replace('/ingredient/', '');
  const data: IViewIngredient = useSelector((state: RootState) =>
    selectIngredientState(state),
  );
  const dispatch = useAppDispatch();
  const handleGetIngredient = () => {
    setLoading(true);
    dispatch(getIngredient(id))
      .unwrap()
      .then((ingredient: IViewIngredient) => setFormState(ingredient))
      .catch((e: IError) => {
        setError(e);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleGetIngredient();
  }, []);

  const handleDeleteIngredient = () => {
    // @ts-ignore
    dispatch(deleteIngredient({ id }))
      .unwrap()
      .then(() => {
        // @ts-ignore
        return dispatch(getIngredients({}));
      });
  };

  const handleUpdateIngredient = () => {
    // @ts-ignore
    dispatch(updateIngredient({ id, formState }))
      .unwrap()
      .then(() => {
        setIsEdit(false);
        handleGetIngredient();
      });
  };

  return (
    <Container maxWidth="lg">
      {loading && <Loader />}
      {!loading && data && (
        <>
          {!isEdit && (
            <>
              <div>IMAGE HERE</div>
              <TitleWrapper>Name: {data.name}</TitleWrapper>
              {data.caloriesMap.map((caloriesValue) => {
                if (caloriesValue.caloriesPerHundred !== null) {
                  return (
                    <CaloriesValue
                      key={v4()}
                      caloriesValue={caloriesValue}
                      editable={false}
                    />
                  );
                }
              })}
              {isUserEdit && (
                <Button
                  text="Edit Ingredient"
                  onClick={() => setIsEdit(true)}
                />
              )}
            </>
          )}
          {isEdit && (
            <>
              <div>IMAGE HERE</div>
              <TitleWrapper>
                <SpanNameWrapper>Name:</SpanNameWrapper>
                <TextField
                  required
                  id="outlined-required"
                  variant="standard"
                  defaultValue={formState.name}
                  onChange={(e) =>
                    setFormState({ ...formState, name: e.target.value })
                  }
                />
              </TitleWrapper>
              {formState.caloriesMap.map(
                (caloriesValue: ICaloriesValue, index) => (
                  <CaloriesValue
                    key={`Measurement-${index}`}
                    caloriesValue={caloriesValue}
                    editable={true}
                    formState={formState}
                    toggleEnabled={() => {
                      setFormState({
                        ...formState,
                        caloriesMap: formState.caloriesMap.map(
                          (record: ICaloriesValue) => {
                            if (record.type !== caloriesValue.type) {
                              return record;
                            }

                            return {
                              caloriesPerHundred: record.caloriesPerHundred,
                              type: record.type,
                              enabled: !record.enabled,
                            };
                          },
                        ),
                      });
                    }}
                    updateCalories={(value) => {
                      setFormState({
                        ...formState,
                        caloriesMap: formState.caloriesMap.map(
                          (record: ICaloriesValue) => {
                            if (record.type !== caloriesValue.type) {
                              return record;
                            }

                            return {
                              caloriesPerHundred: value,
                              type: record.type,
                              enabled: record.enabled,
                            };
                          },
                        ),
                      });
                    }}
                  />
                ),
              )}
              {isUserEdit && (
                <>
                  <Button
                    text="Save"
                    onClick={() => handleUpdateIngredient()}
                  />
                  <Button
                    text="Cancel"
                    onClick={() => {
                      setIsEdit(false);
                      setFormState(data);
                    }}
                  />
                  {isAdmin && (
                    <Button
                      text="Delete Ingredient"
                      onClick={() => handleDeleteIngredient()}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ViewIngredient;
