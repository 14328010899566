import { v4 } from 'uuid';

import React from 'react';

import {
  INumberedList,
  IParagraph,
} from '../../../containers/ViewRecipe/types';
import NumberedList from './NumberedList';
import Paragraph from './Paragraph';

const Instructions: React.FC<{
  values: IParagraph[] | INumberedList[];
}> = ({ values }) => {
  const displayChild = (value: IParagraph | INumberedList) => {
    switch (value.type) {
      case 'numbered-list':
        return <NumberedList value={value} />;
      case 'paragraph':
        return <Paragraph value={value} />;
    }
  };

  return (
    <span>
      {values.map((value: IParagraph | INumberedList) => {
        return <div key={v4()}>{displayChild(value)}</div>;
      })}
    </span>
  );
};

export default Instructions;
