import {
  IInitialValue,
  initialValue,
} from '../components/RichTextEditor/helpers';
import { IAddRecipe, IRecipeIngredient } from '../types';
import { IAddCategory } from '../types/categories';

export const AddRecipeInitialState: IAddRecipe = {
  title: '',
  categoryId: '',
  instructions: initialValue as IInitialValue[],
  images: [] as string[],
  tags: [] as string[],
  recipeIngredients: [] as IRecipeIngredient[],
  time: 0,
  difficulty: 1,
  source: '',
  servings: 1,
};

export const EditRecipeInitialState: IAddRecipe = {
  title: '',
  categoryId: '',
  instructions: [] as IInitialValue[],
  images: [] as string[],
  tags: [] as string[],
  recipeIngredients: [] as IRecipeIngredient[],
  time: 0,
  difficulty: 1,
  source: '',
  servings: 1,
};

export const AddCategoryInitialState: IAddCategory = {
  name: '',
  image: {
    compressed: '',
    original: '',
    vibrantVariants: {},
    sizeVariants: {},
  },
};
