import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { containerRoot } from './constants/modals';
import Main from './containers/Main';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './styles/index.css';

const root = createRoot(containerRoot);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <Main />
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
