import { v4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Chip } from '@mui/material';

import { Loader } from '../../assets';
import { Button } from '../../components';
import Instructions from '../../components/Recipe/components/Instructions';
import { errorDefault } from '../../constants/defaultTypes';
import { ROLE_USER_EDIT } from '../../constants/roles';
import { editRecipeLink } from '../../constants/routes';
import {
  GROUP_4,
  GROUP_5,
  GROUP_6,
  GROUP_7,
  SPACING_1,
  SPACING_2,
  createSize,
} from '../../foundations';
import { shortenMeasurement } from '../../helpers/settings';
import { getRecipe } from '../../reducers/recipeReducer';
import { selectHasAccess } from '../../selectors/authSelectors';
import { selectRecipeData } from '../../selectors/recipeSelector';
import { RootState, useAppDispatch } from '../../store/store';
import { IError, IRecipeIngredient, IViewRecipe } from '../../types';

const Container = styled.div`
  padding-top: ${SPACING_2};
  display: grid;
  align-content: start;
  align-items: start;
  row-gap: ${SPACING_2};
  grid-template-areas:
    'image'
    'title'
    'category'
    'tags'
    'details'
    'ingredients'
    'instruction';

  @media (min-width: ${GROUP_5}) {
    grid-template-columns: 47% 47%;
    grid-template-rows: auto;
    column-gap: 6%;
    grid-template-areas:
      'title image'
      'category image'
      'tags image'
      'details image'
      'ingredients image'
      '. image'
      'instruction instruction';
  }

  @media (min-width: ${GROUP_6}) {
    column-gap: 6%;
    grid-template-areas:
      'title image'
      'category image'
      'tags image'
      'details image'
      'ingredients image'
      '. image'
      'instruction instruction';
  }

  @media (min-width: ${GROUP_7}) {
    column-gap: 6%;
    grid-template-areas:
      'title image'
      'category image'
      'tags image'
      'details image'
      'ingredients image'
      'instruction image';
  }
`;

const ImageWrapper = styled.img`
  grid-area: image;
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: ${createSize(800)};
`;

const Title = styled.h4`
  grid-area: title;
  text-align: center;
  font-size: 32px;
  padding-top: ${SPACING_2};
`;

const CategoryWrapper = styled.div`
  grid-area: category;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  padding: ${SPACING_1} 0;
`;

const DetailsWrapper = styled.div<{ isFirst?: boolean }>`
  grid-area: details;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const DetailsElement = styled.div`
  color: #000;
  font-size: 12px;
  padding-bottom: ${SPACING_1};
`;

const IngredientsWrapper = styled.div`
  grid-area: ingredients;
  border-top: 1px solid #222;
  margin-top: ${SPACING_2};
`;

const IngredientsTitle = styled.h5`
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
`;

const IngredientsGridWrapper = styled.div`
  display: grid;
  row-gap: ${SPACING_1};

  @media (min-width: ${GROUP_4}) {
    column-gap: 2%;
    grid-template-columns: 49% 49%;
  }
`;

const IngredientsElementWrapper = styled.div`
  display: grid;
  column-gap: 2%;
  grid-template-columns: 70px auto;
  grid-template-areas: 'ingredientAmount ingredientDescription';
`;

const IngredientAmountValue = styled.div`
  grid-area: ingredientAmount;
  justify-self: right;
  text-transform: uppercase;
`;

const IngredientDescription = styled.div`
  grid-area: ingredientDescription;
  justify-self: left;
`;

const InstructionsWrapper = styled.div`
  grid-area: instruction;
  border-top: 1px solid #222;
  margin-top: ${SPACING_2};

  @media (min-width: ${GROUP_5}) {
    border-top: none;
  }

  @media (min-width: ${GROUP_7}) {
    border-top: 1px solid #222;
  }
`;

const InstructionsTitle = styled.h5`
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
`;

const IngredientInstructions = styled.div`
  font-size: 12px;
  font-style: italic;
`;

const IngredientGroupTitle = styled.h6``;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: ${SPACING_1} 0;
`;

const ViewRecipe: React.FC = () => {
  const [_, setError] = useState<IError>(errorDefault);
  const [loading, setLoading] = useState(true);

  const slug = location.pathname.replace('/recipe/', '');
  const data: IViewRecipe = useSelector((state: RootState) =>
    selectRecipeData(state),
  );
  const hasAccess = useSelector((state: RootState) => selectHasAccess(state));
  const dispatch = useAppDispatch();
  const handleGetRecipe = (servings?: number) => {
    setLoading(true);
    let args = {
      slug,
    } as IViewRecipe;
    if (servings !== undefined) {
      args['servings'] = servings;
    }
    dispatch(getRecipe(args))
      .unwrap()
      .catch((e: IError) => {
        setError(e);
      });

    setLoading(false);
  };
  useEffect(() => {
    handleGetRecipe();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      {!loading && data && (
        <>
          <Title>{data.title}</Title>
          {data.images[0]?.original && (
            <ImageWrapper src={data.images[0].original}></ImageWrapper>
          )}
          <CategoryWrapper>{data.category.name}</CategoryWrapper>
          <DetailsWrapper>
            <DetailsElement>Star: {data.difficulty}</DetailsElement>
            <DetailsElement>Prep time: {data.time}min</DetailsElement>
            <DetailsElement>Servings: {data.servings}</DetailsElement>
            <DetailsElement>KCAL: {data.calories}</DetailsElement>
            <DetailsElement>Weight: {data.weight}g</DetailsElement>
          </DetailsWrapper>

          <IngredientsWrapper>
            <IngredientsTitle>Ingredients</IngredientsTitle>
            {Object.keys(data.ingredientsGroups).map((key) => {
              const recipeIngredients = data.ingredientsGroups[key];
              return (
                <div key={v4()}>
                  {Object.keys(data.ingredientsGroups).length > 1 && (
                    <IngredientGroupTitle key={v4()}>
                      {key}
                    </IngredientGroupTitle>
                  )}
                  <IngredientsGridWrapper>
                    {recipeIngredients.map((ingredient: IRecipeIngredient) => {
                      let amount = '';
                      let label = '';
                      let description = '';

                      if (ingredient.skipAmount) {
                        label = ingredient.name;
                        description = ingredient.skipAmountDescription;
                      } else {
                        amount = `${ingredient.amount} ${shortenMeasurement(
                          ingredient.measurement,
                        )}`;
                        label = `${ingredient.name}`;
                        description = ingredient.preparationInstructions;
                      }

                      return (
                        <IngredientsElementWrapper key={v4()}>
                          <IngredientAmountValue>
                            {amount}
                          </IngredientAmountValue>
                          <IngredientDescription>
                            {label}{' '}
                            {description !== '' && (
                              <IngredientInstructions>
                                {description}
                              </IngredientInstructions>
                            )}
                          </IngredientDescription>
                        </IngredientsElementWrapper>
                      );
                    })}
                  </IngredientsGridWrapper>
                </div>
              );
            })}
          </IngredientsWrapper>
          <InstructionsWrapper>
            <InstructionsTitle>Instructions</InstructionsTitle>
            <Instructions values={data.instructions} />
          </InstructionsWrapper>
          {data.tags && (
            <TagsWrapper>
              {data.tags.map((tag: string) => (
                <Chip label={tag} key={v4()} />
              ))}
            </TagsWrapper>
          )}
        </>
      )}
      {hasAccess(ROLE_USER_EDIT) && (
        <Link to={editRecipeLink(data.id)}>
          <Button text="Edit Recipe" />
        </Link>
      )}
    </Container>
  );
};

export default ViewRecipe;
