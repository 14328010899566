import styled from 'styled-components';

import { GROUP_5 } from '../../foundations';

const ContentContainer = styled.div<{ isMain?: boolean }>`
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: auto;
  margin-left: auto;
  background-color: #fdfdfd;
  ${({ isMain }) => isMain && 'min-height: 100vh;'};
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 250px auto;
  grid-template-areas: 'content content';

  @media (min-width: ${GROUP_5}) {
    grid-template-areas: 'sidebar content';
  }
`;

export default ContentContainer;
