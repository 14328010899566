import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiFavouriteIds, apiFavouriteSet } from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { IChangeFavouriteValue, IProfileState } from '../types';

const initialState: IProfileState = {
  favouritesIds: [],
};

export const fetchFavouriteRecipeIds = createAsyncThunk(
  'profile/getFavouriteIds',
  async ({}, thunkApi) => {
    try {
      const response = await fetch(apiFavouriteIds, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (response.status !== 200) {
        throw new Error('Unable to fetch favorite recipe ids');
      }

      return await response.json();
    } catch (e: any) {
      console.log(e);
      thunkApi.rejectWithValue({});
    }
  },
);

export const changeFavouriteStatus = createAsyncThunk<
  IChangeFavouriteValue,
  IChangeFavouriteValue
>(
  'profile/changeFavouriteStatus',
  // @ts-ignore
  async ({ id, value }: IChangeFavouriteValue, thunkAPI) => {
    try {
      const response = await fetch(apiFavouriteSet(id, value ? 1 : 0), {
        method: 'POST',
        headers: getHeaders(),
      });

      if (response.status === 202) {
        return;
      }

      throw new Error('Unable to change favourite status');
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchFavouriteRecipeIds.fulfilled}`]: (state, action) => {
      state.favouritesIds = action.payload;
    },
    [`${fetchFavouriteRecipeIds.rejected}`]: (state) => {
      state.favouritesIds = [];
    },
  },
});

export default profileSlice.reducer;
