import { createSlice } from '@reduxjs/toolkit';

import { MessageReducerState } from '../types';

const initialState: MessageReducerState = {};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
});
const { reducer } = messageSlice;
export default reducer;
