import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import AppsIcon from '@mui/icons-material/Apps';
import AssistantIcon from '@mui/icons-material/Assistant';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EggIcon from '@mui/icons-material/Egg';

import { LogoutIcon } from '../../assets';
import { modalCategory, modalIngredient } from '../../constants/modals';
import { ROLE_USER_ADMIN, ROLE_USER_EDIT } from '../../constants/roles';
import {
  addRecipeLink,
  categoriesLink,
  favouriteLink,
  homepageLink,
  ingredientsLink,
  loginLink,
  recipesLink,
} from '../../constants/routes';
import {
  GROUP_5,
  SPACING_1,
  SPACING_2,
  SPACING_3,
  SPACING_5,
  SPACING_6,
  SPACING_10,
  SPACING_14,
  createSize,
} from '../../foundations';
import { defaultColours } from '../../foundations/colours';
import { logout } from '../../reducers/authReducer';
import { selectHasAccess } from '../../selectors/authSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import Logo from '../Logo';

const SidebarWrapper = styled.div<{ isCollapsed: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  left: ${({ isCollapsed }) => (isCollapsed ? '-100%' : 0)};
  text-align: center;
  background-color: #f8f6f4;
  padding: ${SPACING_5};
  height: calc(100vh - ${SPACING_14} - ${SPACING_10});
  z-index: 20;
  margin-top: ${SPACING_14};

  @media (min-width: ${GROUP_5}) {
    position: fixed;
    grid-area: sidebar;
    top: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    width: calc(250px - ${SPACING_10});
    height: calc(100vh - ${SPACING_10});
    box-shadow: 3px 0 5px 0 rgb(0 0 0 / 10%);
    margin-top: 0;
  }
`;

const SectionTitle = styled.h3`
  text-transform: uppercase;
  color: gray;
  padding: ${SPACING_1} 0;

  @media (min-width: ${GROUP_5}) {
    width: 100%;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  align-items: flex-start;
  padding-bottom: ${SPACING_6};

  @media (min-width: ${GROUP_5}) {
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  display: none;

  @media (min-width: ${GROUP_5}) {
    display: unset;
    width: 100%;
  }
`;

const LinkItemWrapper = styled.div<{ isActiveRoute?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin: ${SPACING_3} 0;

  &:hover {
    cursor: pointer;
    border-left: ${createSize(4)} solid #d2e9e9;
    padding-left: ${SPACING_2};
  }

  svg {
    fill: ${defaultColours.black};
    margin-right: ${SPACING_1};
  }

  ${({ isActiveRoute }) =>
    isActiveRoute &&
    css`
      border-left: ${createSize(4)} solid #c4dfdf;
      padding-left: ${SPACING_2};
    `};

  @media (min-width: ${GROUP_5}) {
    justify-content: flex-start;
  }
`;

const LogoutIconWrapper = styled.button`
  -webkit-appearance: button;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: ${SPACING_1};
  padding: 0;
  cursor: pointer;
  margin-top: auto;
`;

const Sidebar: React.FC<{
  isCollapsed: boolean;
  openModal: (modalName: string) => void;
  setIsCollapsed: (isCollapsed: boolean) => void;
}> = ({ isCollapsed, openModal, setIsCollapsed }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate(loginLink);
      });
  };

  // @ts-ignore
  const setOpenModal = (modalName: string) => dispatch(openModal(modalName));
  const hasAccess = useSelector((state: RootState) => selectHasAccess(state));

  useEffect(() => {
    if (document) {
      if (!isCollapsed) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isCollapsed]);

  return (
    <SidebarWrapper isCollapsed={isCollapsed}>
      <LogoWrapper>
        <Logo isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      </LogoWrapper>
      <SectionTitle>Library</SectionTitle>
      <SectionContent>
        <LinkItemWrapper isActiveRoute={pathname === homepageLink}>
          <Link to={homepageLink}>
            <AppsIcon /> Dashboard
          </Link>
        </LinkItemWrapper>
        <LinkItemWrapper isActiveRoute={pathname === favouriteLink}>
          <Link to={favouriteLink}>
            <BookmarkIcon /> Favourites
          </Link>
        </LinkItemWrapper>
      </SectionContent>

      <SectionTitle>Discover</SectionTitle>
      <SectionContent>
        <LinkItemWrapper isActiveRoute={pathname === recipesLink()}>
          <Link to={recipesLink()}>
            <AutoStoriesIcon /> Recipes
          </Link>
        </LinkItemWrapper>
        <LinkItemWrapper isActiveRoute={pathname === ingredientsLink}>
          <Link to={ingredientsLink}>
            <EggIcon /> Ingredients
          </Link>
        </LinkItemWrapper>
        <LinkItemWrapper isActiveRoute={pathname === categoriesLink}>
          <Link to={categoriesLink}>
            <AssistantIcon /> Categories
          </Link>
        </LinkItemWrapper>
      </SectionContent>

      {hasAccess(ROLE_USER_EDIT) && (
        <>
          <SectionTitle>Utils</SectionTitle>
          <SectionContent>
            <LinkItemWrapper isActiveRoute={pathname === addRecipeLink}>
              <Link to={addRecipeLink}>
                <AutoStoriesIcon /> Add Recipe
              </Link>
            </LinkItemWrapper>
            <LinkItemWrapper onClick={() => setOpenModal(modalIngredient)}>
              <EggIcon />
              Add Ingredient
            </LinkItemWrapper>
            {hasAccess(ROLE_USER_ADMIN) && (
              <LinkItemWrapper onClick={() => setOpenModal(modalCategory)}>
                <AssistantIcon />
                Add Category
              </LinkItemWrapper>
            )}
          </SectionContent>
        </>
      )}

      <LogoutIconWrapper onClick={() => handleLogout()}>
        <LogoutIcon />
      </LogoutIconWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
