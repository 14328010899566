import { v4 } from 'uuid';

import React from 'react';
import styled from 'styled-components';

import {
  IListElement,
  INumberedList,
} from '../../../containers/ViewRecipe/types';
import ListItem from './ListItem';

const NumberedListWrapper = styled.ul`
  list-style-type: decimal;
`;

const NumberedList: React.FC<{
  value: INumberedList;
}> = ({ value }) => {
  return (
    <NumberedListWrapper>
      {value.children.map((listElement: IListElement) => {
        return <ListItem value={listElement} key={v4()} />;
      })}
    </NumberedListWrapper>
  );
};

export default NumberedList;
