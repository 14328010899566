import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: none;
  stroke: #222;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const LogoutIcon: React.FC = () => {
  return (
    <Icon height="26px" width="26px" viewBox="-1 -1 26 26">
      <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
      <line x1="12" y1="2" x2="12" y2="12" />
    </Icon>
  );
};

export default LogoutIcon;
