import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiTags } from '../constants/api';
import { ITagsQueryParameters, ITagsReducerState } from '../types';

const initialState: ITagsReducerState = {
  tags: [],
};

export const fetchTags = createAsyncThunk<
  ITagsQueryParameters,
  ITagsQueryParameters
>('tags/fetch', async (parameters: ITagsQueryParameters, thunkApi) => {
  try {
    const response = await fetch(apiTags(parameters), {
      method: 'GET',
    });

    if (response.status !== 200) {
      throw new Error('Unable to fetch tags');
    }

    const payload = await response.json();

    return payload.data.map((el: any) => el.value);
  } catch (e: any) {
    thunkApi.rejectWithValue({});
  }
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchTags.fulfilled}`]: (state, action) => {
      state.tags = action.payload;
    },
    [`${fetchTags.rejected}`]: (state) => {
      state.tags = [];
    },
  },
});

export default tagsSlice.reducer;
