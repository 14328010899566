import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { errorDefault } from '../../../constants/defaultTypes';
import { getCategories } from '../../../reducers/categoriesReducer';
import { selectCategoriesData } from '../../../selectors/categoriesSelectors';
import { RootState, useAppDispatch } from '../../../store/store';
import { IError } from '../../../types';

const SelectCategory: React.FC<{
  label: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string;
  required: boolean;
}> = ({ label, onChange, value, required }) => {
  const data = useSelector((state: RootState) => selectCategoriesData(state));
  const [_, setError] = useState<IError>(errorDefault);
  const dispatch = useAppDispatch();

  const handleGetCategories = () => {
    dispatch(getCategories(1))
      .unwrap()
      .catch((e: IError) => {
        console.log({ e });
        setError(e);
      });
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  return (
    <FormControl required fullWidth>
      <InputLabel id="category-label">{label}</InputLabel>
      <Select
        required={required}
        labelId="category-label"
        id="Category"
        label="Category"
        // @ts-ignore
        onChange={onChange}
        value={value}
      >
        {data.map((option, i) => (
          <MenuItem key={i} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCategory;
