import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  apiCategoriesPaginated,
  apiCategory,
  apiRecipeCategory,
} from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { successStatuses } from '../helpers/backendClient';
import { ICategoriesReducerState } from '../types';

const initialState: ICategoriesReducerState = {
  metadata: {
    page: 1,
    onPage: 0,
    pagesCount: 0,
    totalElements: 0,
  },
  data: [],
};

export const getCategories = createAsyncThunk<{ page?: number }, number>(
  'categories/get',
  async (page, thunkAPI) => {
    try {
      const response = await fetch(apiCategoriesPaginated({ page }), {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const addCategory = createAsyncThunk<any, any>(
  'categories/add',
  // @ts-ignore
  async ({ formState }, thunkAPI) => {
    try {
      const response = await fetch(apiRecipeCategory, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(formState),
      });
      let data = await response.json();
      if (successStatuses.includes(response.status)) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data.errors);
      }
    } catch (error: any) {
      throw thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteCategory = createAsyncThunk<any, any>(
  'categories/delete',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(apiCategory(id), {
        method: 'DELETE',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const saveCategory = createAsyncThunk(
  'categories/save',
  // @ts-ignore
  async ({ id, name, image }, thunkAPI) => {
    try {
      const response = await fetch(apiCategory(id), {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({ name, image }),
      });
      let data = await response.json();
      if (response.status === 200 || response.status === 202) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getCategories.fulfilled}`]: (_, action) => {
      return { ...action.payload };
    },
    [`${getCategories.rejected}`]: () => {
      return { ...initialState };
    },
  },
});

export default categoriesSlice.reducer;
