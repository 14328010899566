import React from 'react';
import styled from 'styled-components';

interface IButton {
  onClick?: (e: any) => void;
  text?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  isIcon?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const ButtonWrapper = styled.button<{ isIcon: boolean }>`
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  padding: 10px 24px 8px;
  margin: 4px;
  background-color: ${({ isIcon }) => (isIcon ? 'transparent' : '#1266f1')};
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: bottom;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 4px;
  border: 0;
  cursor: pointer;

  ${({ isIcon }) =>
    !isIcon &&
    `
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  `}

  ${({ isIcon }) =>
    !isIcon &&
    `
  &:disabled,
  &[disabled]{
    border: 1px solid #999999;
    background-color: transparent;
    color: #666666;
    box-shadow: none;
  }`}

  &:hover {
    background-color: ${({ isIcon }) => (isIcon ? 'transparent' : '#0b419b')};
  }
`;

const Button: React.FC<IButton> = ({
  isIcon = false,
  onClick = () => {},
  text,
  disabled,
  type = 'button',
  children,
}: IButton) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      disabled={disabled}
      isIcon={isIcon}
      type={type}
    >
      {children ? children : text}
    </ButtonWrapper>
  );
};

export default Button;
