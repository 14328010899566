import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiRecipeById, apiRecipeBySlug } from '../constants/api';
import { AddRecipeInitialState } from '../constants/initialStates';
import { getHeaders } from '../helpers/auth';
import { buildQuery } from '../helpers/http';
import {
  IGetRecipe,
  IGroupedIngredients,
  IRecipeIngredient,
  IViewRecipe,
} from '../types';

const initialState: IViewRecipe = {
  ...AddRecipeInitialState,
  slug: '',
  calories: 0,
  weight: 0,
  author: '',
  images: [],
  instructions: [],
  ingredientsGroups: {},
  category: {
    id: '',
    image: {
      compressed: '',
      original: '',
      vibrantVariants: {},
      sizeVariants: {},
    },
    name: '',
  },
  id: '',
};

export const deleteRecipe = createAsyncThunk(
  'recipe/delete',
  // @ts-ignore
  async ({ id }, thunkAPI) => {
    try {
      const response = await fetch(apiRecipeById(id), {
        method: 'DELETE',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 202) {
        return;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getRecipe = createAsyncThunk<IGetRecipe, IViewRecipe>(
  'recipe/get',
  async ({ id, slug, servings }: IGetRecipe, thunkAPI) => {
    if (id === null && slug === null) {
      throw new Error('ID or Slug is required to fetch recipe');
    }

    try {
      let url = '';
      if (id !== undefined) {
        url = apiRecipeById(id);
      } else if (slug !== undefined) {
        url = apiRecipeBySlug(slug);
      }
      url += buildQuery({ servings });

      const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        const ingredientsGroups: IGroupedIngredients = {};
        data.recipeIngredients.forEach(
          (recipeIngredient: IRecipeIngredient) => {
            if (ingredientsGroups[recipeIngredient.group] === undefined) {
              ingredientsGroups[recipeIngredient.group] = [recipeIngredient];
            } else {
              ingredientsGroups[recipeIngredient.group] = [
                ...ingredientsGroups[recipeIngredient.group],
                recipeIngredient,
              ];
            }
          },
        );

        return {
          ...data,
          instructions: JSON.parse(data.instructions),
          ingredientsGroups,
        };
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    setRecipeId(state, action) {
      return {
        ...state,
        id: action.payload,
      };
    },
  },
  extraReducers: {
    [`${getRecipe.fulfilled}`]: (_, action) => {
      return { ...action.payload };
    },
    [`${getRecipe.rejected}`]: () => {
      return initialState;
    },
    [`${deleteRecipe.fulfilled}`]: (state, _) => {
      console.log({ state });
      return { ...state };
    },
  },
});

export const { setRecipeId } = recipeSlice.actions;
export default recipeSlice.reducer;
