import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Alert, FormControl, IconButton } from '@mui/material';

import { apiGenerateImagePreflight } from '../../../constants/api';
import { errorDefault } from '../../../constants/defaultTypes';
import { getHeaders } from '../../../helpers/auth';
import { IError, IGenerateUrlResponse } from '../../../types';

const Input = styled.input`
  display: none;
`;

const ImagePreview = styled.img`
  height: 200px;
  width: fit-content;
`;

const UploadImage: React.FC<{
  formState: any;
  setFormState: (value: any) => void;
}> = ({ formState, setFormState }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrls, setImageUrls] = useState<IGenerateUrlResponse>({});

  useEffect(() => {
    if (formState.images.length > 0) {
      setImageUrls({
        accessUrl: formState.images[0],
      });
      setIsUploaded(true);
    } else {
      setImageUrls({});
      setIsUploaded(false);
    }
  }, [formState.images]);

  const [generateUrlGetError, setGenerateUrlGetError] =
    useState<IError>(errorDefault);

  const removeImage = () => {
    setFormState({
      ...formState,
      images: [],
    });
  };

  const getUrl = () => {
    setIsUploaded(false);
    fetch(apiGenerateImagePreflight, {
      method: 'POST',
      headers: getHeaders(),
    })
      .then((res) => res.json())
      .then((data: IGenerateUrlResponse) => {
        setGenerateUrlGetError(errorDefault);
        setImageUrls(data);
      })
      .catch((error) => {
        setGenerateUrlGetError(error);
        setImageUrls({});
      });
  };

  const onSelectFile = (event: any) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const selectedFile = files[0];
    fetch(`${imageUrls.uploadUrl}`, {
      method: 'PUT',
      headers: {
        'Content-Type': selectedFile.type,
      },
      body: selectedFile,
    })
      .then(() => {
        setFormState({
          ...formState,
          images: [imageUrls.accessUrl],
        });
        setIsUploaded(true);
      })
      .catch((error) => {
        setGenerateUrlGetError(error);
        setIsUploaded(false);
      });
  };

  return (
    <FormControl fullWidth>
      {!isUploaded && (
        <label htmlFor="icon-button-file">
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            onClick={() => getUrl()}
            onChange={(event) => onSelectFile(event)}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
      )}

      {generateUrlGetError?.message && (
        <Alert severity="error">{generateUrlGetError.message}</Alert>
      )}

      {isUploaded && <ImagePreview src={imageUrls.accessUrl} />}
      {isUploaded && (
        <IconButton onClick={() => removeImage()}>
          <DeleteIcon />
        </IconButton>
      )}
    </FormControl>
  );
};

export default UploadImage;
