import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';

import { Modal } from '../../components';
import { errorDefault } from '../../constants/defaultTypes';
import { EIngredientType } from '../../constants/enums';
import { containerRoot } from '../../constants/modals';
import { SPACING_2, SPACING_10 } from '../../foundations';
import { replaceIngredientValue } from '../../helpers/common';
import {
  addIngredients,
  getIngredients,
} from '../../reducers/ingredientsReducer';
import { closeModal } from '../../reducers/modalReducer';
import { useAppDispatch } from '../../store/store';
import { IAddIngredient, ICaloriesValue, IError } from '../../types';
import CaloriesValue from '../Ingredients/components/CaloriesValue';

const messageTimeout = 5000;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 95%;
`;

const InputWrapper = styled.div<{ isError?: boolean }>`
  padding: ${SPACING_2};
  width: 100%;
  ${({ isError }) =>
    isError &&
    `
    padding-bottom: ${SPACING_10};
    
    p {
      position: absolute;
      top: 45px;
    }
  
  `}
`;

const AddIngredientModalContainer: React.FC = () => {
  const initialFormState: IAddIngredient = {
    name: '',
    caloriesMap: Object.values(EIngredientType).map(
      (type: EIngredientType) => ({
        type: type,
        caloriesPerHundred: 0,
        enabled: false,
      }),
    ),
  };

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState([initialFormState]);
  const [error, setError] = useState<IError>(errorDefault);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const resetInputs = () => setFormState([initialFormState]);
  const setCloseModal = () => {
    dispatch(closeModal());
    resetInputs();
  };

  const addAdditionalIngredient = () =>
    setFormState([...formState, initialFormState]);

  const handleAddIngredient = () => {
    setError(errorDefault);
    setSuccess(false);
    setLoading(true);
    dispatch(
      // @ts-ignore
      addIngredients({ formState }),
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          dispatch(getIngredients({ page: 1 }));
          setCloseModal();
        }, messageTimeout);
      })
      .catch((e: any) => {
        setError(e);
        setLoading(false);
      });
  };

  // TODO: FIX
  const isSaveDisabled =
    formState.filter(
      (value) =>
        value.name === '' ||
        value.caloriesMap.find((e) => e.caloriesPerHundred !== null) ===
          undefined,
    ).length > 0;

  return ReactDOM.createPortal(
    <Modal
      title="Add Ingredient"
      onClose={() => setCloseModal()}
      onSave={(e) => {
        e.preventDefault();
        handleAddIngredient();
      }}
      disableSaveButton={loading || isSaveDisabled}
      disableCloseButton={loading}
      isLoading={loading}
      isError={!!error.message}
      errorMessage={error.message}
      additionalButtonText="Add more ingredients"
      additionalButtonOnClick={addAdditionalIngredient}
      isSuccess={success}
      successMessage="Good job! Ingredient(s) were added successfully!"
    >
      <FormWrapper>
        {formState.map((values, i) => {
          return (
            <RowWrapper key={i}>
              <InputsWrapper>
                <InputWrapper isError={error?.payload?.ingredients[i]?.name}>
                  <TextField
                    required
                    error={error?.payload?.ingredients[i]?.name}
                    helperText={error?.payload?.ingredients[i]?.name}
                    id="outlined-basic"
                    variant="standard"
                    label="Name"
                    value={values.name}
                    type="text"
                    onChange={(e) =>
                      setFormState(
                        replaceIngredientValue(
                          { name: e.target.value },
                          formState,
                          i,
                        ),
                      )
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  {values.caloriesMap.map(
                    (caloriesValue: ICaloriesValue, index: number) => (
                      <CaloriesValue
                        key={`${i}-${index}`}
                        caloriesValue={caloriesValue}
                        editable={true}
                        formState={values}
                        toggleEnabled={() => {
                          setFormState(
                            replaceIngredientValue(
                              {
                                caloriesMap: values.caloriesMap.map(
                                  (record: ICaloriesValue) => {
                                    if (record.type === caloriesValue.type) {
                                      record.enabled = !record.enabled;
                                    }

                                    return record;
                                  },
                                ),
                              },
                              formState,
                              i,
                            ),
                          );
                        }}
                        updateCalories={(value: number) => {
                          setFormState(
                            replaceIngredientValue(
                              {
                                caloriesMap: values.caloriesMap.map(
                                  (record: ICaloriesValue) => {
                                    if (record.type === caloriesValue.type) {
                                      record.caloriesPerHundred = value;
                                    }

                                    return record;
                                  },
                                ),
                              },
                              formState,
                              i,
                            ),
                          );
                        }}
                      />
                    ),
                  )}
                </InputWrapper>
              </InputsWrapper>
              {i > 0 && (
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    setFormState(formState.filter((item, index) => index !== i))
                  }
                >
                  <CloseIcon />
                </IconButton>
              )}
            </RowWrapper>
          );
        })}
      </FormWrapper>
    </Modal>,
    containerRoot,
  );
};

export default AddIngredientModalContainer;
