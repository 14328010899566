import React from 'react';
import styled from 'styled-components';

import { IParagraph, ITextChild } from '../../../containers/ViewRecipe/types';
import TextChild from './TextChild';

const ParagraphWrapper = styled.p`
  margin: 7px 0 7px 0;
`;

const Paragraph: React.FC<{
  value: IParagraph;
}> = ({ value }) => {
  return (
    <ParagraphWrapper>
      {value.children.map((child: ITextChild, index: number) => {
        return (
          <TextChild value={child} key={`TextChild-${value.type}-${index}`} />
        );
      })}
    </ParagraphWrapper>
  );
};

export default Paragraph;
