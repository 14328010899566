import React from 'react';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { IconButton, TextField } from '@mui/material';

import { Card, DisplayImage, UploadImage } from '../../../components';
import {
  SPACING_1,
  SPACING_2,
  SPACING_3,
  SPACING_4,
  SPACING_13,
} from '../../../foundations';
import { ICategory } from '../../../types';

const TextWrapper = styled.div`
  padding: ${SPACING_3} ${SPACING_2} ${SPACING_1} ${SPACING_2};
`;

const IconButtonWrapper = styled(IconButton)`
  padding: 0 ${SPACING_2};
`;

const UploadImageWrapper = styled.div`
  margin: ${SPACING_4} ${SPACING_2} ${SPACING_2};
  max-width: ${SPACING_13};
  z-index: 1;
`;

const EditableCategory: React.FC<{
  formState: ICategory;
  setFormState: (formState: ICategory) => void;
  saveCategory: () => void;
  deleteCategory: () => void;
  displayCategory: () => void;
}> = ({
  formState,
  setFormState,
  saveCategory,
  deleteCategory,
  displayCategory,
}) => {
  const removeImage = () =>
    setFormState({
      ...formState,
      image: {
        compressed: '',
        original: '',
        vibrantVariants: {},
        sizeVariants: {},
      },
    });
  return (
    <Card
      buttons={
        <>
          <IconButtonWrapper aria-label="save" onClick={saveCategory}>
            <SaveAltIcon />
          </IconButtonWrapper>
          <IconButtonWrapper aria-label="delete" onClick={deleteCategory}>
            <DeleteIcon />
          </IconButtonWrapper>
          <IconButtonWrapper aria-label="close" onClick={displayCategory}>
            <CloseIcon />
          </IconButtonWrapper>
        </>
      }
    >
      <UploadImageWrapper>
        {formState.image.compressed === '' ? (
          <UploadImage
            setImage={(accessUrl) =>
              accessUrl &&
              setFormState({
                ...formState,
                image: {
                  ...formState.image,
                  compressed: accessUrl,
                },
              })
            }
          />
        ) : (
          <DisplayImage
            imageUrl={formState.image.compressed}
            isRemovable
            removeImage={removeImage}
          />
        )}
      </UploadImageWrapper>
      <TextWrapper>
        <TextField
          required
          id="outlined-required"
          variant="standard"
          label="Required"
          defaultValue={formState.name}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
        />
      </TextWrapper>
    </Card>
  );
};

export default EditableCategory;
