import { EIngredientType } from '../constants/enums';

export const shortenMeasurement = (measurement: string) => {
  switch (measurement) {
    case 'grams':
      return 'g';
    case 'kilograms':
      return 'kg';
    case 'millilitres':
      return 'ml';
    case 'litres':
      return 'l';
    case 'table_spoon':
      return 'tbs';
    case 'tea_spoon':
      return 'ts';
    case 'quantity':
      return 'qty';
    case 'cup':
      return 'c';
    default:
      return measurement;
  }
};

export const perQuantityShortenDescription = (
  quantityType: EIngredientType | string,
) => {
  switch (quantityType) {
    case EIngredientType.WEIGHT:
      return '/100 g';
    case EIngredientType.VOLUME:
      return '/100 ml';
    case EIngredientType.QUANTITY:
      return '/1 piece';
    default:
      return '';
  }
};
