import { buildQuery } from '../helpers/http';

export const homepageLink = '/';
export const favouriteLink = '/favourites';

interface IRecipesLinkParameters {
  categoryName?: string;
  phrase?: string;
}

// Recipes
export const recipesLinkEmpty = '/recipes';
export const recipesLink = (parameters?: IRecipesLinkParameters): string => {
  return `/recipes${buildQuery(parameters ?? {})}`;
};

export const viewRecipeLink = (id?: string): string =>
  id ? `/recipe/${id}` : '/recipe/:id/failed';
export const viewRecipeLinkId = '/recipe/:id';
export const editRecipeLink = (id?: string): string =>
  id ? `/recipe/${id}/edit` : `/recipe/${id}/edit/failed`;
export const editRecipeLinkId = '/recipe/:id/edit';

export const addRecipeLink = '/add-recipe';

// IngredientsContainer
export const ingredientsLink = '/ingredients';
export const editIngredientLink = (id?: string): string =>
  id ? `/ingredient/${id}/edit` : `/ingredient/:id/failed`;
export const viewIngredientLinkId = '/ingredient/:id';

// Accounts
export const profileLink = '/profile';
export const loginLink = '/login';
export const signOutLink = '/sign-out';

// CategoriesContainer
export const categoriesLink = '/categories';
export const addCategoryLink = '/add-category';
export const editCategoryLink = (id: string): string => `/category/${id}/edit`;
