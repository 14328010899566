import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Card, Pagination } from '../../components';
import NoResults from '../../components/NoResults';
import { errorDefault } from '../../constants/defaultTypes';
import { SPACING_2, SPACING_4 } from '../../foundations';
import {
  getActiveAlphabet,
  getIngredients,
  updateIngredientsQuery,
} from '../../reducers/ingredientsReducer';
import {
  selectIngredientsData,
  selectIngredientsMetadata,
  selectIngredientsQuery,
} from '../../selectors/ingredientsSelectors';
import { RootState } from '../../store/store';
import { IError } from '../../types';

const IngredientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const IngredientListWrapper = styled(ContentWrapper)`
  align-items: stretch;
`;

const IngredientLink = styled.a`
  text-align: center;
`;

const TitleWrapper = styled.div`
  padding: ${SPACING_4} ${SPACING_2};
  width: 90%;
`;

const IngredientsContainer: React.FC = () => {
  const data = useSelector((state: RootState) => selectIngredientsData(state));
  const metadata = useSelector((state: RootState) =>
    selectIngredientsMetadata(state),
  );
  const query = useSelector((state: RootState) =>
    selectIngredientsQuery(state),
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>(errorDefault);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    // @ts-ignore
    dispatch(getIngredients(query))
      .unwrap()
      .catch((e: IError) => {
        setError(e);
      });
    setLoading(false);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    // @ts-ignore
    dispatch(getActiveAlphabet({}))
      .unwrap()
      .catch((e: IError) => {
        setError(e);
      });
    setLoading(false);
  }, [false]);

  return (
    <IngredientsWrapper>
      <ContentWrapper>
        {!loading && (
          <>
            {error.message && !data && <div>{error.message}</div>}
            {data && (
              <>
                <IngredientListWrapper>
                  {data.map((ingredient, i: number) => (
                    <IngredientLink
                      key={i}
                      href={`/ingredient/${ingredient.id}`}
                    >
                      <Card>
                        <TitleWrapper>{ingredient.name}</TitleWrapper>
                      </Card>
                    </IngredientLink>
                  ))}
                </IngredientListWrapper>

                <Pagination
                  page={metadata.page}
                  pagesCount={metadata.pagesCount}
                  setPage={(page: number) => {
                    dispatch(
                      // @ts-ignore
                      updateIngredientsQuery({
                        ...query,
                        page,
                      }),
                    );
                  }}
                />
              </>
            )}
            {!loading && data && data.length === 0 && !error.message && (
              <NoResults />
            )}
          </>
        )}
      </ContentWrapper>
    </IngredientsWrapper>
  );
};

export default IngredientsContainer;
