import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CookieIcon from '@mui/icons-material/Cookie';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { Alert, Button, Rating } from '@mui/material';

import { RichTextEditor } from '../../components';
import { apiRecipeEdit, apiRecipeWithId } from '../../constants/api';
import { errorDefault } from '../../constants/defaultTypes';
import { EditRecipeInitialState } from '../../constants/initialStates';
import { ROLE_USER_ADMIN } from '../../constants/roles';
import { recipesLink, viewRecipeLink } from '../../constants/routes';
import { GROUP_5, GROUP_7, SPACING_1, SPACING_2 } from '../../foundations';
import { adaptRecipeViewToEditState } from '../../helpers/adapters';
import { BackendRequestBuilder } from '../../helpers/backendClient';
import { createGroupsFromData } from '../../reducers/ingredientsGroupsReducer';
import { selectHasAccess, selectUserId } from '../../selectors/authSelectors';
import { selectIngredientsFromGroups } from '../../selectors/ingredientsGroupsSelector';
import { RootState } from '../../store/store';
import { IEditRecipe, IError, IViewRecipe } from '../../types';
import IngredientsGroups from '../AddRecipe/components/IngredientsGroups';
import SelectCategory from '../AddRecipe/components/SelectCategory';
import SelectTags from '../AddRecipe/components/SelectTags';
import TextInput from '../AddRecipe/components/TextInput';
import UploadImage from '../AddRecipe/components/UploadImage';

const Container = styled.div`
  padding-top: ${SPACING_2};
  display: grid;
  align-content: start;
  align-items: start;
  row-gap: ${SPACING_2};
  grid-template-areas:
    'image'
    'title'
    'category'
    'tags'
    'details'
    'ingredients'
    'instruction';

  @media (min-width: ${GROUP_5}) {
    grid-template-columns: 47% 47%;
    grid-template-rows: auto;
    column-gap: 6%;
    grid-template-areas:
      'image'
      'title'
      'category'
      'tags'
      'details'
      'ingredients'
      'instruction';
  }

  @media (min-width: ${GROUP_7}) {
    grid-template-areas:
      'image'
      'title'
      'category'
      'tags'
      'details'
      'ingredients'
      'instruction';
  }
`;

const Title = styled.h4`
  grid-area: title;
  text-align: center;
  font-size: 32px;
  padding-top: ${SPACING_2};
`;

const CategoryWrapper = styled.div`
  grid-area: category;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  padding: ${SPACING_1} 0;
`;

const DetailsWrapper = styled.div<{ isFirst?: boolean }>`
  grid-area: details;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const DetailsElement = styled.div`
  color: #000;
  font-size: 12px;
  padding-bottom: ${SPACING_1};
`;

const InputWrapper = styled.div`
  padding: ${SPACING_2} 0 ${SPACING_1} 0;
`;

const StyledRating = styled(Rating)`
  & .MuiRating-iconFilled,
  & .MuiRating-iconHover {
    color: #f2c258;
  }
`;

const EditRecipeContainer: React.FC = () => {
  const [formState, setFormState] = useState<IEditRecipe>(
    EditRecipeInitialState,
  );
  const hasAccess = useSelector((state: RootState) => selectHasAccess(state));
  const userId = useSelector((state: RootState) => selectUserId(state));

  const requestBuilder = new BackendRequestBuilder(useNavigate());
  const navigate = useNavigate();
  const [savingError, setSavingError] = useState<IError>(errorDefault);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const recipeIngredients = useSelector((state: RootState) =>
    selectIngredientsFromGroups(state),
  );
  const matches = location.pathname.match(
    '\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}',
  );
  const id = matches && matches.length > 0 ? matches[0] : null;
  const dispatch = useDispatch();
  const saveRecipe = () => {
    const payload = {
      ...formState,
      instructions: JSON.stringify(formState.instructions),
      recipeIngredients: recipeIngredients,
    };

    requestBuilder
      .setUrl(apiRecipeEdit(id ?? ''))
      .setMethod('PUT')
      .setBody(JSON.stringify(payload))
      .setSuccessCallback(() => {
        setIsSuccess(true);
        setTimeout(() => navigate(recipesLink()), 2000);
      })
      .setErrorCallback((error: IError) => {
        setSavingError(error);
        setIsSuccess(false);
      })
      .makeRequest();
  };

  useEffect(() => {
    if (id) {
      requestBuilder
        .setUrl(`${apiRecipeWithId}/${id}`)
        .setSuccessCallback((data: IViewRecipe) => {
          if (data.author !== userId && !hasAccess(ROLE_USER_ADMIN)) {
            navigate(viewRecipeLink(data.slug));
          }

          setSavingError(errorDefault);
          setFormState(adaptRecipeViewToEditState(data));
          // @ts-ignore
          dispatch(createGroupsFromData(data.recipeIngredients));
          // setLoading(false);
        })
        .setErrorCallback((error) => {
          setSavingError(error);
          // setLoading(false);
        })
        .makeRequest();
    }
  }, []);
  return (
    <Container>
      <Title>
        {' '}
        <TextInput
          required
          label="title"
          disabled
          onChange={(e) =>
            setFormState({
              ...formState,
              title: e.target.value,
            })
          }
          value={formState.title}
        />
      </Title>
      <UploadImage formState={formState} setFormState={setFormState} />
      <CategoryWrapper>
        <SelectCategory
          required
          label="Category"
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setFormState({
              ...formState,
              categoryId: e.target.value,
            })
          }
          value={formState.categoryId}
        />
      </CategoryWrapper>
      <DetailsWrapper>
        <DetailsElement>
          Star:{' '}
          <StyledRating
            max={5}
            icon={<CookieIcon fontSize="inherit" />}
            emptyIcon={<CookieOutlinedIcon fontSize="inherit" />}
            name="customized-color"
            value={formState.difficulty}
            defaultValue={1}
            // @ts-ignore
            onChange={(e, newValue = 1) => setRating(newValue)}
          />
        </DetailsElement>
        <DetailsElement>
          Prep time:{' '}
          <TextInput
            required
            label="time"
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) =>
              setFormState({
                ...formState,
                time: parseInt(e.target.value),
              })
            }
            value={formState.time}
            minValue={0}
            type="number"
          />
        </DetailsElement>
        <DetailsElement>
          Servings:{' '}
          <TextInput
            required
            label="servings"
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) =>
              setFormState({
                ...formState,
                servings: parseInt(e.target.value),
              })
            }
            value={formState.servings}
            type="number"
            minValue={1}
          />
        </DetailsElement>
        {/*<DetailsElement>KCAL: {formState.calories}</DetailsElement>*/}
        {/*<DetailsElement>Weight: {formState.weight}g</DetailsElement>*/}
      </DetailsWrapper>

      {formState.instructions && formState.instructions.length > 0 && (
        <InputWrapper>
          <RichTextEditor
            value={formState.instructions}
            onChange={(value) => {
              setFormState({
                ...formState,
                instructions: value,
              });
            }}
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <SelectTags formState={formState} setFormState={setFormState} />
      </InputWrapper>
      <InputWrapper>
        <IngredientsGroups />
      </InputWrapper>
      <Button variant="text" onClick={() => saveRecipe()}>
        Submit
      </Button>

      {isSuccess && (
        <Alert severity="success">Recipe successfully edited</Alert>
      )}

      {savingError?.message && (
        <Alert severity="error">{savingError.message}</Alert>
      )}
    </Container>
  );
};

export default EditRecipeContainer;
