import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  IGroup,
  IIngredientsGroup,
  IIngredientsGroupHashMap,
  IIngredientsGroupedHashMap,
  IRecipeIngredient,
  IRecipeIngredientPayload,
  ISetRecipeIngredients,
} from '../types';

const initialState: IIngredientsGroupHashMap = {
  0: { id: 0, name: 'Default', ingredients: {} },
};

export const createNewGroup = createAsyncThunk<IGroup, IGroup>(
  'ingredientsGroups/addGroup',
  async (newGroup) => {
    return { ...newGroup, ingredients: {} };
  },
);

export const deleteGroup = createAsyncThunk<number, number>(
  'ingredientsGroups/deleteGroup',
  (id) => id,
);

export const renameGroup = createAsyncThunk<IGroup, IGroup>(
  'ingredientsGroups/renameGroup',
  (group) => group,
);

export const addRecipeIngredient = createAsyncThunk<
  IRecipeIngredientPayload,
  IRecipeIngredientPayload
>('ingredientsGroups/addRecipeIngredient', (payload) => payload);

export const editRecipeIngredient = createAsyncThunk<
  IRecipeIngredientPayload,
  IRecipeIngredientPayload
>('ingredientsGroups/editRecipeIngredient', (payload) => payload);

export const setRecipeIngredients = createAsyncThunk<
  ISetRecipeIngredients,
  ISetRecipeIngredients
>('ingredientsGroups/setRecipeIngredient', (payload) => payload);

export const deleteRecipeIngredient = createAsyncThunk<
  IRecipeIngredientPayload,
  IRecipeIngredientPayload
>('ingredientsGroups/deleteRecipeIngredient', (payload) => payload);

export const createGroupsFromData = createAsyncThunk<
  IRecipeIngredient[],
  IIngredientsGroupHashMap
>(
  'ingredientsGroups/createGroupsFromData',
  // @ts-ignore
  (recipeIngredients: IRecipeIngredient[]) => {
    let grouped: IIngredientsGroupedHashMap = {};
    recipeIngredients.forEach((recipeIngredient: IRecipeIngredient) => {
      if (grouped[recipeIngredient.group] === undefined) {
        grouped[recipeIngredient.group] = {};
      }
      grouped[recipeIngredient.group][recipeIngredient.ingredient] =
        recipeIngredient;
    });

    const state: IIngredientsGroupHashMap = {};
    Object.keys(grouped).map((key: string, index: number) => {
      state[index] = {
        id: index,
        name: key,
        ingredients: grouped[key],
      };
    });

    return state;
  },
);

const ingredientsGroupsSlice = createSlice({
  name: 'ingredientsGroups',
  initialState,
  reducers: {},
  extraReducers: {
    [`${createNewGroup.fulfilled}`]: (state, action) => {
      const payload: IIngredientsGroup = action.payload;
      return { ...state, [payload.id]: payload };
    },
    [`${deleteGroup.fulfilled}`]: (state, action) => {
      const nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    },
    [`${renameGroup.fulfilled}`]: (state, action) => {
      state[action.payload.id].name = action.payload.name;
    },
    [`${addRecipeIngredient.fulfilled}`]: (state, action) => {
      state[action.payload.groupId].ingredients[
        action.payload.recipeIngredient.ingredient
      ] = { ...action.payload.recipeIngredient };
    },
    [`${editRecipeIngredient.fulfilled}`]: (state, action) => {
      state[action.payload.groupId].ingredients[
        action.payload.recipeIngredient.ingredient
      ] = { ...action.payload.recipeIngredient };
    },
    [`${deleteRecipeIngredient.fulfilled}`]: (state, action) => {
      delete state[action.payload.groupId].ingredients[
        action.payload.recipeIngredient.ingredient
      ];
    },
    [`${setRecipeIngredients.fulfilled}`]: (state, action) => {
      state[action.payload.groupId].ingredients = {};
      action.payload.recipeIngredients.forEach(
        (recipeIngredient: IRecipeIngredient) => {
          state[action.payload.groupId].ingredients[
            recipeIngredient.ingredient
          ] = { ...recipeIngredient };
        },
      );
    },
    [`${createGroupsFromData.fulfilled}`]: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default ingredientsGroupsSlice.reducer;
