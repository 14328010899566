import { isHexColour } from './common';

// RGB transforms
export const rgbToHsl = (rgb: number[]) => {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h;
  let s;

  if (max === min) {
    h = 0;
  } else if (r === max) {
    h = (g - b) / delta;
  } else if (g === max) {
    h = 2 + (b - r) / delta;
  } else {
    h = 4 + (r - g) / delta;
  }

  h = Math.min(h * 60, 360);

  if (h < 0) {
    h += 360;
  }

  const l = (min + max) / 2;

  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }

  return [h, s * 100, l * 100];
};

export const rgbToHex = (rgb: number[]) => {
  if (rgb) {
    const [r, g, b] = rgb;
    // eslint-disable-next-line no-bitwise
    return `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)}`.slice(0, 7);
  }

  return '';
};

// HEX transforms
export const hexToRgb = (hex: string): number[] => {
  // @ts-ignore
  const result: string[] =
    /#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})/.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return [r, g, b];
};

export const hexToHsl = (hex: string) => rgbToHsl(hexToRgb(hex));

// HSL transforms
const pickRGBColours = (
  hue: number,
  chroma: number,
  component: number,
  lightness: number,
) => {
  let red;
  let green;
  let blue;

  if (hue < 1) {
    red = chroma;
    green = component;
    blue = 0;
  } else if (hue < 2) {
    red = component;
    green = chroma;
    blue = 0;
  } else if (hue < 3) {
    red = 0;
    green = chroma;
    blue = component;
  } else if (hue < 4) {
    red = 0;
    green = component;
    blue = chroma;
  } else if (hue < 5) {
    red = component;
    green = 0;
    blue = chroma;
  } else {
    red = chroma;
    green = 0;
    blue = component;
  }

  const lightnessAdjustment = lightness - chroma / 2;
  red += lightnessAdjustment;
  green += lightnessAdjustment;
  blue += lightnessAdjustment;

  return {
    red,
    green,
    blue,
  };
};

export const hslToRgb = (hsl: number[], isAdjustment = false) => {
  // based on algorithm from http://en.wikipedia.org/wiki/HSL_and_HSV#Converting_to_RGB
  const [hue, saturation, lightness] = hsl;
  let huePrime = hue / 60;

  if (huePrime < 0) {
    huePrime = 6 - (-huePrime % 6);
  }

  huePrime %= 6;

  let calculatedSaturation = saturation;
  let calculatedLightness = lightness;

  if (isAdjustment) {
    calculatedSaturation = Math.max(0, Math.min(1, saturation / 100));
    calculatedLightness = Math.max(0, Math.min(1, lightness / 100));
  }
  const chroma =
    (1 - Math.abs(2 * calculatedLightness - 1)) * calculatedSaturation;
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1));

  huePrime = Math.floor(huePrime);

  const { red, green, blue } = pickRGBColours(
    huePrime,
    chroma,
    secondComponent,
    calculatedLightness,
  );

  return [
    Math.round(red * 255),
    Math.round(green * 255),
    Math.round(blue * 255),
  ];
};

export const hslToHex = (hsl: number[]) => {
  const rgb = hslToRgb(hsl, true);

  return rgbToHex(rgb);
};

// Other
export const convertToHsl = (colour: string | number[]) =>
  // @ts-ignore
  isHexColour(colour) ? hexToHsl(colour) : rgbToHsl(colour);

export const adjustRGBLightness = (rgb: number[], adjustment: number) => {
  const hsl = rgbToHsl(rgb);
  const flooredHue = Math.floor(hsl[0]);
  const flooredSaturation = Math.floor(hsl[1]);
  const adjustedLightness = Math.floor(hsl[2]) + adjustment;
  let floorHSL = [];
  if (adjustedLightness <= 0) {
    floorHSL = [flooredHue, flooredSaturation, 0];
  } else if (adjustedLightness >= 100) {
    floorHSL = [flooredHue, flooredSaturation, 100];
  } else {
    floorHSL = [flooredHue, flooredSaturation, adjustedLightness];
  }

  return hslToRgb([floorHSL[0], floorHSL[1] / 100, floorHSL[2] / 100]);
};

export const setRGBLightness = (rgb: number[], lightnessValue: number) => {
  const hsl = rgbToHsl(rgb);
  const floorHSL = [
    Math.floor(hsl[0]),
    Math.floor(hsl[1]),
    Math.floor(lightnessValue),
  ];

  switch (true) {
    case lightnessValue < 0:
      return hslToRgb([floorHSL[0], floorHSL[1] / 100, 0]);
    case lightnessValue > 100:
      return hslToRgb([floorHSL[0], floorHSL[1] / 100, 1]);
    default:
      return hslToRgb([floorHSL[0], floorHSL[1] / 100, floorHSL[2] / 100]);
  }
};

export const calculateLuminance = (colour: string | number[]) => {
  // @ts-ignore
  const convertedColour: number[] = isHexColour(colour)
    ? // @ts-ignore
      hexToRgb(colour)
    : colour;
  const rgbColour = convertedColour.map((c) => {
    const calculatedColour = c / 255;

    if (calculatedColour <= 0.03928) {
      return calculatedColour / 12.92;
    }

    return ((calculatedColour + 0.055) / 1.055) ** 2.4;
  });

  return 0.2126 * rgbColour[0] + 0.7152 * rgbColour[1] + 0.0722 * rgbColour[2];
};

export const calculateContrast = (colour1: number[], colour2: number[]) => {
  const L1 = calculateLuminance(colour1);
  const L2 = calculateLuminance(colour2);

  if (L1 < L2) {
    return (L2 + 0.05) / (L1 + 0.05);
  }

  return (L1 + 0.05) / (L2 + 0.05);
};
