import { css, cx } from '@emotion/css';

import React, { PropsWithChildren, Ref } from 'react';

import { BaseProps, OrNull } from '../helpers';

const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>,
  ) => (
    <div
      {...props}
      ref={ref as React.RefObject<HTMLDivElement>}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }

          & > * + * {
            margin-left: 15px;
          }
        `,
      )}
    />
  ),
);

Menu.displayName = 'Menu';

export default Menu;
