import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { ContentContainer, Logo, Sidebar } from '..';
import { loginLink } from '../../constants/routes';
import { GROUP_5, SPACING_5, createSize } from '../../foundations';
import { getCurrentTimestamp } from '../../helpers/auth';
import { logout } from '../../reducers/authReducer';
import { updateIngredientsQuery } from '../../reducers/ingredientsReducer';
import { updateRecipesQuery } from '../../reducers/recipesReducer';
import {
  selectExpiryDate,
  selectIsLoggedIn,
} from '../../selectors/authSelectors';
import { RootState, useAppDispatch } from '../../store/store';

const Content = styled.div`
  grid-area: content;
  padding: 0 ${SPACING_5};
`;

const LogoWrapper = styled.div`
  @media (min-width: ${GROUP_5}) {
    display: none;
  }
`;

const SearchBarWrapper = styled.div`
  margin-top: ${SPACING_5};
  height: ${createSize(76)};
`;

const ProtectedRoutes: React.FC<{
  openModal: (modalName: string) => void;
}> = ({ openModal }) => {
  const { pathname } = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState(
    pathname === '/ingredients' ? 'ingredients' : 'recipes',
  );
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isLoggedIn = useSelector((state: RootState) => selectIsLoggedIn(state));
  const expiryDate = useSelector((state: RootState) => selectExpiryDate(state));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTimestamp = getCurrentTimestamp();
  const isTokenExpired = currentTimestamp >= expiryDate;

  useEffect(() => {
    if (pathname === '/ingredients') {
      setRadioButtonValue('ingredients');
    } else {
      setRadioButtonValue('recipes');
    }
  }, [pathname]);

  useEffect(() => {
    if (isTokenExpired) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          navigate(loginLink);
        });
    }
  }, [isTokenExpired]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (radioButtonValue === 'recipes') {
        dispatch(
          updateRecipesQuery({
            phrase: searchValue,
            page: 1,
          }),
        );
      } else {
        dispatch(
          updateIngredientsQuery({
            search: searchValue,
            page: 1,
          }),
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchValue, inputRef]);

  return isLoggedIn && !isTokenExpired ? (
    <>
      <ContentContainer isMain>
        <Sidebar
          isCollapsed={isCollapsed}
          openModal={openModal}
          setIsCollapsed={setIsCollapsed}
        />
        <Content>
          <LogoWrapper>
            <Logo isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          </LogoWrapper>
          <SearchBarWrapper>
            <FormControl fullWidth>
              <TextField
                fullWidth
                variant="standard"
                id="cookbook-search"
                ref={inputRef}
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={searchValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(event.target.value)
                }
              />
            </FormControl>
            <RadioGroup
              row
              aria-labelledby="search-radio-buttons-group"
              defaultValue={
                pathname === '/ingredients' ? 'ingredients' : 'recipes'
              }
              name="radio-buttons-group"
              onChange={(e) => setRadioButtonValue(e.target.value)}
            >
              <FormControlLabel
                value="recipes"
                control={<Radio />}
                label="Recipes"
              />
              <FormControlLabel
                value="ingredients"
                control={<Radio />}
                label="Ingredients"
              />
            </RadioGroup>
          </SearchBarWrapper>
          <Outlet />
        </Content>
      </ContentContainer>
    </>
  ) : (
    <Navigate to={loginLink} />
  );
};

export default ProtectedRoutes;
