import {
  ROLE_USER_ADMIN,
  ROLE_USER_EDIT,
  ROLE_USER_VIEW,
} from '../constants/roles';

const HIERARCHY = {
  [`${ROLE_USER_VIEW}`]: [ROLE_USER_VIEW],
  [`${ROLE_USER_EDIT}`]: [ROLE_USER_EDIT, ROLE_USER_VIEW],
  [`${ROLE_USER_ADMIN}`]: [ROLE_USER_ADMIN, ROLE_USER_EDIT, ROLE_USER_VIEW],
};

export const hasAccessForRolesAndRequirement = (
  userRoles: string[],
  requirement: string,
): boolean => {
  let result = false;
  userRoles.forEach((role: string) => {
    if (!HIERARCHY[role]) {
      return;
    }

    if (HIERARCHY[role].indexOf(requirement) !== -1) {
      result = true;
    }
  });

  return result;
};
