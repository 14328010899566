import React from 'react';

import { ITextChild } from '../../../containers/ViewRecipe/types';

const TextChild: React.FC<{
  value: ITextChild;
}> = ({ value }) => {
  return (
    <span
      style={{
        fontWeight: value.bold ? 'bold' : 'normal',
        textDecoration: value.underline ? 'underline' : 'none',
      }}
    >
      {value.text}
    </span>
  );
};

export default TextChild;
