import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ModalReducerState } from '../types';

const initialState: ModalReducerState = {
  isOpen: false,
  modalName: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      return { ...state, isOpen: true, modalName: action.payload };
    },
    closeModal: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: {},
});

const { reducer, actions } = modalSlice;
export const { openModal, closeModal } = actions;
export default reducer;
