import React from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styled from 'styled-components';

import ProtectedRoutes from '../components/ProtectedRoutes';
import {
  modalCategory,
  modalIngredient,
  removeRecipe,
} from '../constants/modals';
import {
  addRecipeLink,
  categoriesLink,
  editRecipeLinkId,
  favouriteLink,
  homepageLink,
  ingredientsLink,
  loginLink,
  recipesLinkEmpty,
  viewIngredientLinkId,
  viewRecipeLinkId,
} from '../constants/routes';
import { openModal } from '../reducers/modalReducer';
import {
  selectIsOpenModal,
  selectModalName,
} from '../selectors/modalSelectors';
import { RootState } from '../store/store';
import AddCategoryModalContainer from './AddCategoryModal';
import AddIngredientModalContainer from './AddIngredientModal';
import AddRecipeContainer from './AddRecipe';
import CategoriesContainer from './Categories';
import EditRecipeContainer from './EditRecipe';
import Favourites from './Favourites';
import Homepage from './Homepage';
import IngredientsContainer from './Ingredients';
import Login from './Login';
import Recipes from './Recipes';
import RemoveRecipeModalContainer from './RemoveRecipeModal';
import ViewIngredient from './ViewIngredient';
import ViewRecipe from './ViewRecipe';

const BackgroundWrapper = styled.div<{ isOpenModal: boolean }>`
  display: ${({ isOpenModal }) => (isOpenModal ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: ${({ isOpenModal }) =>
    isOpenModal ? 'rgb(34, 34, 34, 0.3)' : 'rgb(34, 34, 34, 0)'};
`;

const Main: React.FC = () => {
  const modalName = useSelector((state: RootState) => selectModalName(state));
  const isOpenModal = useSelector((state: RootState) =>
    selectIsOpenModal(state),
  );

  return (
    <Router>
      <Routes>
        <Route path={loginLink} element={<Login />} />
        <Route element={<ProtectedRoutes openModal={openModal} />}>
          <Route path={homepageLink} element={<Homepage />} />
          <Route path={favouriteLink} element={<Favourites />} />
          <Route path={recipesLinkEmpty} element={<Recipes />} />
          <Route path={ingredientsLink} element={<IngredientsContainer />} />
          <Route path={viewIngredientLinkId} element={<ViewIngredient />} />
          <Route path={categoriesLink} element={<CategoriesContainer />} />
          <Route path={addRecipeLink} element={<AddRecipeContainer />} />
          <Route path={viewRecipeLinkId} element={<ViewRecipe />} />
          <Route path={editRecipeLinkId} element={<EditRecipeContainer />} />
        </Route>
      </Routes>

      {/*Modals Section*/}
      <BackgroundWrapper isOpenModal={isOpenModal}>
        {modalName === modalIngredient && <AddIngredientModalContainer />}
        {modalName === modalCategory && <AddCategoryModalContainer />}
        {modalName === removeRecipe && <RemoveRecipeModalContainer />}
      </BackgroundWrapper>
    </Router>
  );
};

export default Main;
