import React, { ReactElement } from 'react';

interface ILeaf {
  bold?: true;
  code?: true;
  italic?: true;
  underline?: true;
}
const Leaf: React.FC<{
  attributes: any;
  children?: ReactElement;
  leaf: ILeaf;
}> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
