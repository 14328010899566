import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { ROLE_USER_ADMIN } from '../../../constants/roles';
import {
  deleteCategory,
  saveCategory,
} from '../../../reducers/categoriesReducer';
import { selectHasAccess } from '../../../selectors/authSelectors';
import { RootState, useAppDispatch } from '../../../store/store';
import { ICategory } from '../../../types';
import EditableCategory from './EditableCategory';
import UneditableCategory from './UneditableCategory';

const Category: React.FC<{
  category: ICategory;
  triggerDateRefresh: Dispatch<SetStateAction<boolean>>;
}> = ({ category, triggerDateRefresh }) => {
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [formState, setFormState] = useState<ICategory>(category);
  const hasAccess = useSelector((state: RootState) => selectHasAccess(state));
  const isAdmin = hasAccess(ROLE_USER_ADMIN);
  const handleDeleteCategory = () => {
    dispatch(deleteCategory({ id: category.id }))
      .unwrap()
      .then(() => {
        setIsEdit(false);
        triggerDateRefresh(true);
      });
  };

  const handleSaveCategory = () => {
    dispatch(
      // @ts-ignore
      saveCategory({
        id: category.id,
        name: formState.name,
        image: formState.image,
      }),
    )
      .unwrap()
      .then(() => {
        setIsEdit(false);
        triggerDateRefresh(true);
      });
  };

  return (
    <>
      {isEdit && isAdmin ? (
        <EditableCategory
          formState={formState}
          setFormState={(formState) => setFormState(formState)}
          displayCategory={() => setIsEdit(false)}
          saveCategory={() => handleSaveCategory()}
          deleteCategory={() => handleDeleteCategory()}
        />
      ) : (
        <UneditableCategory
          category={category}
          isAdmin={isAdmin}
          editCategory={() => setIsEdit(true)}
          deleteCategory={() => handleDeleteCategory()}
        />
      )}
    </>
  );
};

export default Category;
