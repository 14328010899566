import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CookieIcon from '@mui/icons-material/Cookie';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { Alert, Button, FormControl, Rating, Typography } from '@mui/material';

import { RichTextEditor } from '../../components';
import { apiRecipe } from '../../constants/api';
import { errorDefault } from '../../constants/defaultTypes';
import { AddRecipeInitialState } from '../../constants/initialStates';
import { recipesLink } from '../../constants/routes';
import { SPACING_1, SPACING_2 } from '../../foundations';
import { BackendRequestBuilder } from '../../helpers/backendClient';
import { selectIngredientsFromGroups } from '../../selectors/ingredientsGroupsSelector';
import { RootState } from '../../store/store';
import { IAddRecipe, IError } from '../../types';
import IngredientsGroups from './components/IngredientsGroups';
import SelectCategory from './components/SelectCategory';
import SelectTags from './components/SelectTags';
import TextInput from './components/TextInput';
import UploadImage from './components/UploadImage';

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
`;

const StyledRating = styled(Rating)`
  & .MuiRating-iconFilled,
  & .MuiRating-iconHover {
    color: #f2c258;
  }
`;

const InputWrapper = styled.div`
  padding: ${SPACING_2} 0 ${SPACING_1} 0;
`;

const AddRecipeContainer: React.FC = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<IAddRecipe>(AddRecipeInitialState);
  const [savingError, setSavingError] = useState<IError>(errorDefault);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const requestBuilder = new BackendRequestBuilder(navigate);
  const recipeIngredients = useSelector((state: RootState) =>
    selectIngredientsFromGroups(state),
  );

  const saveRecipe = () => {
    const payload = {
      ...formState,
      instructions: JSON.stringify(formState.instructions),
      recipeIngredients: recipeIngredients,
    };

    requestBuilder
      .setUrl(apiRecipe)
      .setMethod('POST')
      .setBody(JSON.stringify(payload))
      .setSuccessCallback(() => {
        setSavingError(errorDefault);
        setIsSuccess(true);
        setTimeout(() => navigate(recipesLink()), 2000);
      })
      .setErrorCallback((error) => setSavingError(error))
      .makeRequest();
  };

  const setRating = (newValue = 1) =>
    setFormState({
      ...formState,
      difficulty: newValue,
    });

  return (
    <StyledFormControl>
      <Title>Add Recipe</Title>
      <UploadImage formState={formState} setFormState={setFormState} />
      <InputWrapper>
        <TextInput
          label="title"
          required
          onChange={(e) =>
            setFormState({
              ...formState,
              title: e.target.value,
            })
          }
          value={formState.title}
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          label="Source"
          onChange={(e) =>
            setFormState({
              ...formState,
              source: e.target.value,
            })
          }
          value={formState.source}
        />
      </InputWrapper>
      <InputWrapper>
        <SelectCategory
          label="Category"
          required
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setFormState({
              ...formState,
              categoryId: e.target.value,
            })
          }
          value={formState.categoryId}
        />
      </InputWrapper>
      <InputWrapper>
        <RichTextEditor
          value={formState.instructions}
          onChange={(value) => {
            setFormState({
              ...formState,
              instructions: value,
            });
          }}
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          required
          label="time"
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setFormState({
              ...formState,
              time: parseInt(e.target.value),
            })
          }
          value={formState.time}
          minValue={0}
          type="number"
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          required
          label="servings"
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setFormState({
              ...formState,
              servings: parseInt(e.target.value),
            })
          }
          value={formState.servings}
          minValue={1}
          type="number"
        />
      </InputWrapper>
      <InputWrapper>
        <FormControl fullWidth>
          <Typography component="legend">Difficulty</Typography>
          <StyledRating
            max={5}
            icon={<CookieIcon fontSize="inherit" />}
            emptyIcon={<CookieOutlinedIcon fontSize="inherit" />}
            name="customized-color"
            value={formState.difficulty}
            defaultValue={1}
            // @ts-ignore
            onChange={(e, newValue = 1) => setRating(newValue)}
          />
        </FormControl>
      </InputWrapper>
      <InputWrapper>
        <SelectTags formState={formState} setFormState={setFormState} />
      </InputWrapper>
      <InputWrapper>
        <IngredientsGroups />
      </InputWrapper>

      <Button variant="text" onClick={() => saveRecipe()}>
        Submit
      </Button>

      {isSuccess && <Alert severity="success">Recipe successfully added</Alert>}

      {savingError?.message && (
        <Alert severity="error">{savingError.message}</Alert>
      )}
    </StyledFormControl>
  );
};

export default AddRecipeContainer;
