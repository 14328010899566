import { v4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FavouriteRecipe from '../../components/FavouriteRecipe';
import { favouriteLink } from '../../constants/routes';
import { GROUP_5, SPACING_2, SPACING_4 } from '../../foundations';
import { fetchFavouriteRecipes } from '../../reducers/homepageReducer';
import { selectHomepageFavouriteRecipes } from '../../selectors/homepageSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { IViewRecipe } from '../../types';

const SectionWrapper = styled.section`
  padding-top: ${SPACING_2};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h4`
  position: relative;
  font-size: 26px;
  font-weight: 700;
  padding: ${SPACING_4} 0;
`;

const FavouriteRecipesWrapper = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${GROUP_5}) {
    flex-wrap: nowrap;
  }
`;

const SeeAllLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FavouriteRecipeWrapper = styled.li`
  list-style-type: none;
  padding-bottom: ${SPACING_4};

  @media (min-width: ${GROUP_5}) {
    margin-right: ${SPACING_4};
  }
`;

const Homepage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const favouriteRecipes = useSelector((state: RootState) =>
    selectHomepageFavouriteRecipes(state),
  );
  const dispatch = useAppDispatch();

  const handleGetFavourites = () => {
    dispatch(fetchFavouriteRecipes({ onPage: 3, page: 1 }));
  };

  useEffect(() => {
    setLoading(true);
    handleGetFavourites();
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && favouriteRecipes?.length > 0 && (
        <SectionWrapper>
          <TitleWrapper>
            <Title>Your recent favourites</Title>
            <SeeAllLink to={favouriteLink}>
              See all <ChevronRightIcon />
            </SeeAllLink>
          </TitleWrapper>
          <FavouriteRecipesWrapper>
            {favouriteRecipes.map((recipe: IViewRecipe) => (
              <FavouriteRecipeWrapper key={v4()}>
                <FavouriteRecipe
                  recipe={recipe}
                  handleGetFavourites={handleGetFavourites}
                  isFavourite
                />
              </FavouriteRecipeWrapper>
            ))}
          </FavouriteRecipesWrapper>
        </SectionWrapper>
      )}
    </>
  );
};

export default Homepage;
