import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { Cookies } from 'react-cookie';

import { apiLogin } from '../constants/api';
import { getToken, isValidBearer } from '../helpers/auth';
import { IAuthReducerState, IAuthResponse, JWTPayload } from '../types';

const getDecodedToken = (token: string | undefined): JWTPayload | null => {
  if (token === undefined) {
    return null;
  }

  return jwt_decode(token);
};

const bearerToken = getToken('bearer');
const refreshToken = getToken('refreshToken');
const expiryDate = parseInt(getToken('expiryDateToken'));
const initialState: IAuthReducerState = isValidBearer(bearerToken)
  ? {
      isLoggedIn: true,
      bearer: bearerToken,
      refreshToken,
      expiryDate,
      roles: getDecodedToken(bearerToken)?.roles ?? [],
      userId: getDecodedToken(bearerToken)?.sub ?? null,
    }
  : {
      isLoggedIn: false,
      bearer: '',
      refreshToken: '',
      expiryDate: 0,
      roles: [],
      userId: null,
    };

export const login = createAsyncThunk(
  'auth/login',
  // @ts-ignore
  async ({ email, password }, thunkAPI) => {
    try {
      const response: any = await fetch(apiLogin, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      let data: IAuthResponse = await response.json();
      if (response.status === 200) {
        const cookies = new Cookies();
        const decodedToken: JWTPayload = jwt_decode(data.token);
        const expiryDate = decodedToken.exp;
        cookies.set('bearer', data.token, { path: '/' });
        cookies.set('refreshToken', data.refreshToken, { path: '/' });
        cookies.set('expiryDateToken', expiryDate, { path: '/' });
        return { ...data, expiryDate, roles: decodedToken.roles };
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  const cookies = new Cookies();
  cookies.remove('bearer');
  cookies.remove('refreshToken');
  cookies.remove('expiryDateToken');
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [`${login.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        bearer: action.payload.bearer,
        refreshToken: action.payload.refreshToken,
        expiryDate: action.payload.expiryDate,
        roles: action.payload.roles,
      };
    },
    [`${login.rejected}`]: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        bearer: '',
        refreshToken: '',
        expiryDate: 0,
        roles: [],
      };
    },
    [`${logout.fulfilled}`]: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        bearer: '',
        refreshToken: '',
        expiryDate: 0,
        roles: [],
      };
    },
  },
});

export default authSlice.reducer;
