import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@mui/material';

import { adaptIngredientViewToRecipeIngredient } from '../../../helpers/adapters';
import {
  createNewGroup,
  deleteGroup,
  renameGroup,
} from '../../../reducers/ingredientsGroupsReducer';
import { selectAllGroups } from '../../../selectors/ingredientsGroupsSelector';
import { selectIngredientsData } from '../../../selectors/ingredientsSelectors';
import { RootState, useAppDispatch } from '../../../store/store';
import { IViewIngredient } from '../../../types';
import IngredientsDetails from './IngredientsDetails';
import IngredientsSearch from './IngredientsSearch';
import TextInput from './TextInput';

const Wrapper = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
`;
const Title = styled.p``;

const Group = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 3px;
`;

const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const IngredientsGroups: React.FC = () => {
  const [nextGroupIndex, setNextGroupIndex] = useState(1);
  const dispatch = useAppDispatch();
  const options = useSelector((state: RootState) =>
    selectIngredientsData(state),
  ).map((ingredient: IViewIngredient) =>
    adaptIngredientViewToRecipeIngredient(ingredient),
  );
  const groups = useSelector((state: RootState) => selectAllGroups(state));

  const addNewGroup = () => {
    // todo: move this logic into the reducer
    const name =
      Object.keys(groups).length === 0
        ? 'Default'
        : `New group ${nextGroupIndex}`;
    dispatch(
      createNewGroup({
        name,
        id: nextGroupIndex,
      }),
    ).unwrap();
    setNextGroupIndex(nextGroupIndex + 1);
  };

  const removeGroup = (id: number) => {
    if (Object.keys(groups).length === 1) {
      setNextGroupIndex(0);
    }
    dispatch(deleteGroup(id));
  };

  const changeGroupName = (id: number, name: string) =>
    dispatch(renameGroup({ id, name }));

  return (
    <Wrapper>
      <Title>Ingredients Groups</Title>
      {Object.keys(groups).map((key: string, index: number) => {
        const group = groups[parseInt(key)];
        return (
          <Group key={index}>
            <GroupHeader>
              <TextInput
                label="Group name"
                onChange={(e) => changeGroupName(group.id, e.target.value)}
                value={group.name}
              />
              <Button variant="text" onClick={() => removeGroup(group.id)}>
                Remove group
              </Button>
            </GroupHeader>
            <IngredientsSearch options={options} group={group} />
            <IngredientsDetails group={group} />
          </Group>
        );
      })}
      <Button variant="text" onClick={() => addNewGroup()}>
        Add new group
      </Button>
    </Wrapper>
  );
};

export default IngredientsGroups;
