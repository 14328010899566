import jwt_decode from 'jwt-decode';

import { Cookies } from 'react-cookie';
import { NavigateFunction } from 'react-router-dom';

import { apiRefresh } from '../constants/api';
import { bearerToken, refreshToken } from '../constants/cookies';
import { getHeaders, getToken } from './auth';

interface JWTPayload {
  exp: number;
  iat: number;
  roles: string[];
  username: string;
}

export const successStatuses = [200, 201, 202, 204];

export class BackendRequestBuilder {
  constructor(private navigate: NavigateFunction | null = null) {}

  private url: string = '';
  private method: string = 'GET';
  private fetchAnonymously: boolean = false;
  private body: string | null = null;
  private successCallback: ((data: any) => void) | null = null;
  private errorCallback: ((error: any) => void) | null = null;

  public async makeRequest(): Promise<void> {
    if (!this.fetchAnonymously) {
      const token = getToken('bearer');
      if (!token) {
        this.errorCallback &&
          this.errorCallback({
            error: 'Unauthorised use of protected endpoint',
          });

        return;
      }

      const decodedToken: JWTPayload = jwt_decode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      // Token has expired, time for refresh
      if (currentTimestamp >= decodedToken.exp) {
        /*      const result = await this.callRefreshTokenAction();
        if (!result) {
          //this.navigate && this.navigate(loginLink);
        }*/
      }
    }

    fetch(this.url, {
      headers: this.fetchAnonymously ? [] : getHeaders(),
      method: this.method,
      body: this.body,
    })
      .then(async (response) => {
        if (response.status === 403) {
          console.log('Unauthorized');
          return;
        }
        if (successStatuses.includes(response.status)) {
          this.successCallback && this.successCallback(await response.json());
        } else {
          this.errorCallback && this.errorCallback(await response.json());
        }
      })
      .catch(async (error) => {
        if (this.errorCallback) {
          this.errorCallback({ error: error });
        }
      });
  }

  public setUrl(url: string): this {
    this.url = url;

    return this;
  }

  public setMethod(method: string): this {
    this.method = method;

    return this;
  }

  public setBody(body: string): this {
    this.body = body;

    return this;
  }

  public setFetchAnonymously(fetchAnonymously: boolean): this {
    this.fetchAnonymously = fetchAnonymously;

    return this;
  }

  public setSuccessCallback(successCallback: (data: any) => void): this {
    this.successCallback = successCallback;

    return this;
  }

  public setErrorCallback(errorCallback: (error: any) => void): this {
    this.errorCallback = errorCallback;

    return this;
  }

  private callRefreshTokenAction = async (): Promise<boolean> => {
    const cookies = new Cookies();
    const oldRefreshToken = getToken(refreshToken);

    return await fetch(apiRefresh, {
      body: JSON.stringify({ refreshToken: oldRefreshToken }),
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        cookies.set(bearerToken, data.token, { path: '/' });
        cookies.set(refreshToken, data.refreshToken, { path: '/' });
        return true;
      })
      .catch(() => {
        // If this fails for whatever reason, redirect to login page
        cookies.remove(bearerToken);
        cookies.remove(refreshToken);
        return false;
      });
  };
}
