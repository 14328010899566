import { css, cx } from '@emotion/css';

import React, { PropsWithChildren, Ref } from 'react';

import { BaseProps, OrNull } from '../helpers';

const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed?: boolean;
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => (
    <span
      {...props}
      ref={ref as React.RefObject<HTMLSpanElement>}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversed
            ? active
              ? 'white'
              : '#aaa'
            : active
            ? 'black'
            : '#ccc'};
        `,
      )}
    />
  ),
);
Button.displayName = 'Button';
export default Button;
