import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Card, Grid, Skeleton } from '@mui/material';

import { errorDefault } from '../../constants/defaultTypes';
import { getCategories } from '../../reducers/categoriesReducer';
import { updateRecipesQuery } from '../../reducers/recipesReducer';
import { selectCategoriesData } from '../../selectors/categoriesSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { IError, IRecipesQueryParameters, Shape } from '../../types';
import { ICategory } from '../../types';

const Container = styled.div`
  top: 8px;
  background-color: aliceblue;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`;

const TextWrapper = styled.div<{ isActive: boolean }>`
  padding: 4px 8px;
  background-color: ${({ isActive }) => (isActive ? '#a2c4fd' : '#f5f5f5')};
  margin: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
`;

const StickySidebar: React.FC<{
  queryState: IRecipesQueryParameters;
  updateSearchParameters: (values: Shape) => void;
}> = ({ queryState, updateSearchParameters }) => {
  const dispatch = useAppDispatch();
  const data = useSelector((state: RootState) => selectCategoriesData(state));

  const [loading, setLoading] = useState(true);
  const [_, setError] = useState<IError>(errorDefault);
  const handleGetCategories = () => {
    setLoading(true);
    dispatch(getCategories(1))
      .unwrap()
      .catch((e: IError) => {
        console.log({ e });
        setError(e);
      });
    setLoading(false);
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleSettingCategoryId = (selectedCategoryName: string) => {
    if (selectedCategoryName === queryState.categoryName) {
      dispatch(
        // @ts-ignore
        updateRecipesQuery({
          ...queryState,
          page: 1,
          categoryName: undefined,
        }),
      );

      updateSearchParameters({
        categoryName: null,
        page: null,
      });
      return;
    }

    updateSearchParameters({
      categoryName: selectedCategoryName,
      page: null,
    });

    dispatch(
      // @ts-ignore
      updateRecipesQuery({
        ...queryState,
        page: 1,
        categoryName: selectedCategoryName,
      }),
    );
  };

  return (
    <Container>
      <Card elevation={3}>
        <CategoriesContainer>
          <ContentWrapper>
            {loading && (
              <>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" />
                </Grid>
              </>
            )}
            {!loading &&
              data &&
              data.map((category: ICategory, i: number) => (
                <TextWrapper
                  isActive={category.name === queryState.categoryName}
                  key={i}
                  onClick={() => handleSettingCategoryId(category.name)}
                >
                  {category.name}
                </TextWrapper>
              ))}
          </ContentWrapper>
        </CategoriesContainer>
      </Card>
    </Container>
  );
};

export default StickySidebar;
