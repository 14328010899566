import { css, cx } from '@emotion/css';

import React, { PropsWithChildren, Ref } from 'react';

import { BaseProps, OrNull } from '../helpers';
import Menu from './Menu';

const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>,
  ) => (
    <Menu
      {...props}
      ref={ref as React.RefObject<HTMLDivElement>}
      className={cx(
        className,
        css`
          position: relative;
          padding-bottom: 5px;
          border-bottom: 2px solid #eee;
          margin-bottom: 20px;
        `,
      )}
    />
  ),
);

Toolbar.displayName = 'Toolbar';

export default Toolbar;
