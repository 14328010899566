import { defaultColours } from '../../foundations/colours';
import { isColourLight, pickAccessibleColours } from './accessibility';
import {
  adjustRGBLightness,
  convertToHsl,
  hexToRgb,
  rgbToHex,
  setRGBLightness,
} from './conversion';

const SIX_DIGIT_HEX_COLOUR_WITH_HASH_REGEX = /^#[0-9a-fA-F]{6}$/;
const RGB_VALUES_REGEX = /(\d{1,3}?(,\s?\d{1,3}?){2})/;

export const isHexColour = (colour: string) =>
  SIX_DIGIT_HEX_COLOUR_WITH_HASH_REGEX.test(colour);

export const isRgbColour = (colour: number[]) => {
  if (!Array.isArray(colour)) {
    return false;
  }

  if (colour.length !== 3) {
    return false;
  }

  return RGB_VALUES_REGEX.test(colour.join(','));
};

export const isSkinColour = (hsl: number[] = [0, 0, 0]) => {
  const hue = hsl[0];
  const saturation = hsl[1];
  const lightness = hsl[2];
  const hueRange = hue >= 8 && hue <= 42;
  const saturationRange = saturation >= 4 && saturation <= 100;
  const lightnessRange = lightness >= 0 && lightness <= 60;
  return hueRange && saturationRange && lightnessRange;
};

export const checkIfValidColour = (colour: string | number[]) =>
  // @ts-ignore
  isHexColour(colour) || isRgbColour(colour);

const pickDominantTextColour = (backgroundColour: string | number[]) =>
  isColourLight(backgroundColour) ? defaultColours.black : defaultColours.white;

export const getAccessibleColours = (backgroundColourArg: string) => {
  const backgroundColour = checkIfValidColour(backgroundColourArg)
    ? backgroundColourArg
    : defaultColours.white;
  const formattedBackgroundColour = hexToRgb(backgroundColour);

  const darkenBackgroundColour = isSkinColour(convertToHsl(backgroundColour))
    ? setRGBLightness(formattedBackgroundColour, 7)
    : adjustRGBLightness(formattedBackgroundColour, -10);

  const dominantTextColour = pickDominantTextColour(darkenBackgroundColour);
  const backgroundColours = pickAccessibleColours(
    // @ts-ignore
    rgbToHex(darkenBackgroundColour),
    dominantTextColour,
  );

  return {
    // @ts-ignore
    dominantColour: hexToRgb(backgroundColours.backgroundColour),
    dominantTextColour,
  };
};
