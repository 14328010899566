import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal/Modal';
import { errorDefault } from '../../constants/defaultTypes';
import { containerRoot } from '../../constants/modals';
import { closeModal } from '../../reducers/modalReducer';
import { deleteRecipe, setRecipeId } from '../../reducers/recipeReducer';
import { getRecipes } from '../../reducers/recipesReducer';
import { selectRecipeId } from '../../selectors/recipeSelector';
import { RootState } from '../../store/store';
import { IError } from '../../types';

const messageTimeout = 5000;

const RemoveRecipeModalContainer: React.FC = () => {
  const recipeId: string = useSelector((state: RootState) =>
    selectRecipeId(state),
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IError>(errorDefault);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const setCloseModal = () => {
    dispatch(closeModal());
    dispatch(setRecipeId(''));
  };

  const handleDeleteRecipe = () => {
    setError(errorDefault);
    setSuccess(false);
    setLoading(true);
    dispatch(
      // @ts-ignore
      deleteRecipe({ id: recipeId }),
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        setSuccess(true);

        setTimeout(() => {
          dispatch(
            // @ts-ignore
            getRecipes({
              query: {
                page: 1,
              },
            }),
          );
          setCloseModal();
        }, messageTimeout);
      })
      .catch((e: any) => {
        setError(e);
        setLoading(false);
      });
  };

  return ReactDOM.createPortal(
    <Modal
      title="Remove Recipe"
      onClose={() => setCloseModal()}
      onSave={(e) => {
        e.preventDefault();
        handleDeleteRecipe();
      }}
      disableSaveButton={loading}
      disableCloseButton={loading}
      isLoading={loading}
      isError={!!error.message}
      errorMessage={error.message}
      isSuccess={success}
      additionalButtonText=""
      additionalButtonOnClick={() => {}}
      successMessage="Good job! Recipe was removed!"
      messageBarTiming={messageTimeout}
    >
      Are you sure you want to delete this recipe?
    </Modal>,
    containerRoot,
  );
};

export default RemoveRecipeModalContainer;
