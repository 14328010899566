import { buildQuery } from '../helpers/http';
import {
  IIngredientsQueryParameters,
  IPaginationQueryParameters,
  IRecipesQueryParameters,
  ITagsQueryParameters,
} from '../types';

const apiRoute = 'https://api.yourcookbook.uk/api';

export const apiCategories = `${apiRoute}/recipe/category`;
export const apiCategoriesPaginated = (
  parameters?: IPaginationQueryParameters,
) => `${apiRoute}/recipe/category${buildQuery(parameters ?? {})}`;
export const apiCategory = (id: string) => `${apiCategories}/${id}`;
export const apiFavouriteIds = `${apiRoute}/recipe/favourite/ids`;
export const apiFavouriteRecipes = (parameters?: IPaginationQueryParameters) =>
  `${apiRoute}/recipe/favourite${buildQuery(parameters ?? {})}`;
export const apiFavouriteSet = (recipeId: string, value: number) =>
  `${apiRoute}/recipe/${recipeId}/favourite/${value}`;
export const apiGenerateImagePreflight = `${apiRoute}/generate/url`;
export const apiIngredient = (id: string) => `${apiRoute}/ingredient/${id}`;
export const apiIngredients = (parameters?: IPaginationQueryParameters) =>
  `${apiRoute}/ingredient${buildQuery(parameters ?? {})}`;
export const apiIngredientIndexes = `${apiRoute}/ingredient/indexes`;
export const apiIngredientsBulk = `${apiRoute}/ingredient/bulk`;
export const apiIngredientsSearch = (
  parameters: IIngredientsQueryParameters,
): string => `${apiRoute}/ingredient${buildQuery(parameters)}`;
export const apiLogin = `${apiRoute}/auth/login`;
export const apiRecipe = `${apiRoute}/recipe`;
export const apiRecipeById = (id: string) => `${apiRoute}/recipe/${id}`;
export const apiRecipeBySlug = (slug: string) =>
  `${apiRoute}/recipe/slug/${slug}`;
export const apiRecipeCategory = `${apiRoute}/recipe/category`;
export const apiRecipeEdit = (id: string) => `${apiRoute}/recipe/${id}`;
export const apiRecipePaginated = (parameters: IRecipesQueryParameters) =>
  `${apiRoute}/recipe${buildQuery(parameters)}`;
export const apiRecipeWithId = `${apiRoute}/recipe`;
export const apiRefresh = `${apiRoute}/auth/refresh`;
export const apiTags = (parameters: ITagsQueryParameters) =>
  `${apiRoute}/tag${buildQuery(parameters ?? {})}`;
