import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiCategoriesPaginated, apiFavouriteRecipes } from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { IFavouriteRecipesQueryParameters, IHomepageReducer } from '../types';
import { ICategoriesResponse } from '../types';

const categoriesInitialState: ICategoriesResponse = {
  metadata: {
    page: 1,
    onPage: 0,
    pagesCount: 0,
    totalElements: 0,
  },
  data: [],
};

const initialState: IHomepageReducer = {
  favouriteRecipes: [],
  categories: categoriesInitialState,
};

export const fetchHomePageCategories = createAsyncThunk(
  'homepage/fetchCategories',
  // @ts-ignore
  async ({ page }, thunkAPI) => {
    try {
      const response = await fetch(apiCategoriesPaginated(page), {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchFavouriteRecipes = createAsyncThunk<
  IFavouriteRecipesQueryParameters,
  IFavouriteRecipesQueryParameters
>('homepage/fetchFavourite', async ({ onPage, page }, thunkAPI) => {
  try {
    const response = await fetch(
      apiFavouriteRecipes({
        onPage: onPage,
        page: page,
      }),
      {
        method: 'GET',
        headers: getHeaders(),
      },
    );
    if (response.status === 200) {
      const responseBody = await response.json();
      return responseBody.data;
    } else {
      throw new Error('Unable to fetch favourite status');
    }
  } catch (e: any) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});

const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchFavouriteRecipes.fulfilled}`]: (state, action) => {
      state.favouriteRecipes = action.payload;
    },
    [`${fetchFavouriteRecipes.rejected}`]: (state) => {
      state.favouriteRecipes = [];
    },
    [`${fetchHomePageCategories.fulfilled}`]: (state, action) => {
      state.categories = action.payload;
    },
    [`${fetchHomePageCategories.rejected}`]: (state) => {
      state.categories = categoriesInitialState;
    },
  },
});

export default homepageSlice.reducer;
