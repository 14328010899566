import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FavoriteBorder } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { viewRecipeLink } from '../../constants/routes';
import { GROUP_5, SPACING_4, SPACING_20, createSize } from '../../foundations';
import { defaultColours } from '../../foundations/colours';
import {
  getAccessibleColours,
  hexToRgb,
  rgbToHex,
} from '../../helpers/colour-utils';
import { changeFavouriteStatus } from '../../reducers/profileReducer';
import { useAppDispatch } from '../../store/store';
import { IViewRecipe } from '../../types';

const NoImagePlaceholder =
  'https://i0.wp.com/www.mimisrecipes.com/wp-content/uploads/2018/12/recipe-placeholder-featured.jpg?resize=700%2C400&ssl=1';

const RecipeWrapper = styled.div<{
  shadowColour: string;
}>`
  position: relative;
  &:hover {
    ${({ shadowColour }) =>
      shadowColour &&
      css`
        transform: translateY(-5px);
      `};
  }
`;

const FavouriteIconWrapper = styled.div<{ iconColour: string }>`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 4;
  cursor: pointer;

  ${({ iconColour }) =>
    iconColour &&
    css`
      svg {
        fill: ${iconColour};
        transition: all ease-in-out 0.25s;
      }
    `}

  &:hover {
    svg {
      opacity: 0.7;
    }
  }
`;

const ContentWrapper = styled.div<{
  backgroundImage: string;
  shadowColour: string;
}>`
  position: relative;
  display: flex;
  align-items: flex-end;
  aspect-ratio: 1 / 1;
  border-radius: 16px;
  overflow: hidden;
  padding: ${SPACING_4};
  width: calc(100vw - ${SPACING_20});
  ${({ shadowColour }) =>
    shadowColour &&
    css`
      box-shadow: 0 5px 10px 0 rgba(${shadowColour}, 0.5);
    `};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    ${({ shadowColour }) =>
      shadowColour && `background: rgba(${shadowColour}, 0.3)`};

    height: 100%;
    width: 100%;
    z-index: 2;
  }

  &:hover {
    ${({ shadowColour }) =>
      shadowColour &&
      css`
        box-shadow: 0 10px 20px 2px rgba(${shadowColour}, 0.3);
      `};
  }

  @media (min-width: ${GROUP_5}) {
    width: ${createSize(195)};
  }
`;

const Title = styled.h6<{ textColour: string }>`
  z-index: 3;
  color: ${({ textColour }) => textColour};
  font-size: 20px;
  margin: 0;
`;

const FavouriteRecipe: React.FC<{
  recipe: IViewRecipe;
  handleGetFavourites: () => void;
  isFavourite?: boolean;
}> = ({ recipe, handleGetFavourites, isFavourite }) => {
  let newColourPalette = {
    backgroundColour: hexToRgb(defaultColours.black).join(', '),
    textColour: defaultColours.white,
  };

  if (recipe.images[0]?.vibrantVariants.length > 0) {
    const vibrantVariant = recipe.images[0].vibrantVariants.find(
      (variants: any) => variants.variant === 'Vibrant',
    ).rgb;
    const backgroundColour = rgbToHex(vibrantVariant);
    const { dominantColour, dominantTextColour } =
      getAccessibleColours(backgroundColour);
    newColourPalette = {
      backgroundColour: dominantColour.join(','),
      textColour: dominantTextColour,
    };
  }

  const [parsedFavourite, setParsedFavourite] = useState(isFavourite);
  const dispatch = useAppDispatch();
  const imageUrl =
    recipe.images.length > 0 ? recipe.images[0].original : NoImagePlaceholder;

  const updateFavouriteStatus = () => {
    dispatch(
      changeFavouriteStatus({
        id: recipe.id,
        value: !parsedFavourite,
      }),
    )
      .unwrap()
      .then(() => {
        handleGetFavourites();
        setParsedFavourite(!parsedFavourite);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <RecipeWrapper shadowColour={newColourPalette.backgroundColour}>
      {parsedFavourite ? (
        <FavouriteIconWrapper
          aria-label="remove from favorites"
          onClick={() => updateFavouriteStatus()}
          iconColour={newColourPalette.textColour}
        >
          <FavoriteIcon />
        </FavouriteIconWrapper>
      ) : (
        <FavouriteIconWrapper
          aria-label="add to favorites"
          onClick={() => updateFavouriteStatus()}
          iconColour={newColourPalette.textColour}
        >
          <FavoriteBorder />
        </FavouriteIconWrapper>
      )}

      <Link to={viewRecipeLink(recipe.slug)}>
        <ContentWrapper
          backgroundImage={imageUrl}
          shadowColour={newColourPalette.backgroundColour}
        >
          <Title textColour={newColourPalette.textColour}>{recipe.title}</Title>
        </ContentWrapper>
      </Link>
    </RecipeWrapper>
  );
};

export default FavouriteRecipe;
