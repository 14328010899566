import { useSlate } from 'slate-react';

import React, { ReactComponentElement } from 'react';

import { isMarkActive, toggleMark } from '../helpers';
import { Button } from './index';

const MarkButton: React.FC<{
  format: string;
  icon: ReactComponentElement<any>;
}> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

export default MarkButton;
