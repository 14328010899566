import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { shortenMeasurement } from '../../../helpers/settings';
import {
  deleteRecipeIngredient,
  editRecipeIngredient,
} from '../../../reducers/ingredientsGroupsReducer';
import { useAppDispatch } from '../../../store/store';
import { IIngredientsGroup, IRecipeIngredient } from '../../../types';
import TextInput from './TextInput';

const ListItemTextEnhanced = styled.div`
  padding: 0 5px 0 10px;
  width: 125px;
`;

const IngredientsDetails: React.FC<{
  group: IIngredientsGroup;
}> = ({ group }) => {
  const dispatch = useAppDispatch();

  const removeIngredient = (recipeIngredient: IRecipeIngredient) => {
    dispatch(
      deleteRecipeIngredient({
        groupId: group.id,
        recipeIngredient,
      }),
    );
  };

  const updateIngredient = (recipeIngredient: IRecipeIngredient) => {
    dispatch(
      editRecipeIngredient({
        groupId: group.id,
        recipeIngredient,
      }),
    );
  };

  return (
    <div>
      {Object.keys(group.ingredients).length > 0 && (
        <List>
          {Object.keys(group.ingredients).map((key) => {
            const recipeIngredient = group.ingredients[key];

            const isSingleMeasurementType =
              recipeIngredient.availableMeasurements.length === 1;
            const defaultValue = isSingleMeasurementType
              ? recipeIngredient.availableMeasurements[0]
              : '';
            if (
              isSingleMeasurementType &&
              recipeIngredient.measurement === ''
            ) {
              updateIngredient({
                ...recipeIngredient,
                measurement: defaultValue,
              });
            }
            return (
              <div key={key}>
                <ListItem>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeIngredient(recipeIngredient)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ListItemTextEnhanced>
                    {recipeIngredient.name}
                  </ListItemTextEnhanced>
                  <FormControl
                    component="fieldset"
                    required
                    fullWidth
                    sx={{ mr: 3, width: 150 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => {
                            updateIngredient({
                              ...recipeIngredient,
                              skipAmount: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Skip measurements"
                    />
                  </FormControl>
                  {!recipeIngredient.skipAmount && (
                    <FormControl required fullWidth sx={{ mr: 3, width: 100 }}>
                      <TextInput
                        required
                        label="Amount"
                        variant="standard"
                        onChange={(
                          e: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >,
                        ) => {
                          updateIngredient({
                            ...recipeIngredient,
                            amount: parseFloat(e.target.value),
                          });
                        }}
                        value={recipeIngredient.amount}
                        type="number"
                      />
                    </FormControl>
                  )}
                  {!recipeIngredient.skipAmount && (
                    <FormControl required sx={{ mr: 3, width: 150 }}>
                      {isSingleMeasurementType ? (
                        <TextField
                          id={`${key}-measurement-label`}
                          label="Measurement"
                          variant="standard"
                          defaultValue={shortenMeasurement(defaultValue)}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      ) : (
                        <>
                          <InputLabel id={`${key}-measurement-label`}>
                            Measurement
                          </InputLabel>
                          <Select
                            labelId={`${key}-measurement-label`}
                            id={`${key}-measurement-label`}
                            label="Measurement"
                            value={recipeIngredient.measurement}
                            defaultValue={defaultValue}
                            onChange={(e: SelectChangeEvent<string>) => {
                              updateIngredient({
                                ...recipeIngredient,
                                measurement: e.target.value,
                              });
                            }}
                          >
                            {recipeIngredient.availableMeasurements.map(
                              (measurement: string, mIndex: number) => (
                                <MenuItem key={mIndex} value={measurement}>
                                  {shortenMeasurement(measurement)}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </>
                      )}
                    </FormControl>
                  )}
                  {!recipeIngredient.skipAmount && (
                    <FormControl required fullWidth sx={{ mr: 3, width: 400 }}>
                      <TextInput
                        label="Preparations Instructions"
                        variant="standard"
                        onChange={(
                          e: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >,
                        ) => {
                          updateIngredient({
                            ...recipeIngredient,
                            preparationInstructions: e.target.value,
                          });
                        }}
                        value={recipeIngredient.preparationInstructions}
                        type="text"
                      />
                    </FormControl>
                  )}
                  {recipeIngredient.skipAmount && (
                    <FormControl required fullWidth sx={{ mr: 3, width: 400 }}>
                      <TextInput
                        label="Use and amount description"
                        variant="standard"
                        onChange={(
                          e: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >,
                        ) => {
                          updateIngredient({
                            ...recipeIngredient,
                            skipAmountDescription: e.target.value,
                          });
                        }}
                        value={recipeIngredient.skipAmountDescription}
                        type="text"
                      />
                    </FormControl>
                  )}
                </ListItem>
              </div>
            );
          })}
        </List>
      )}
    </div>
  );
};

export default IngredientsDetails;
