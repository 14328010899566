import { Cookies } from 'react-cookie';

import { bearerToken } from '../constants/cookies';

export const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

export const isValidBearer = (value: string) => {
  return value && !value.includes('undefined');
};

export const getToken = (
  tokenType: 'bearer' | 'refreshToken' | 'expiryDateToken',
  withPrefix = true,
) => {
  const cookies = new Cookies();
  const token = cookies.get(tokenType);
  if (tokenType === bearerToken && withPrefix) {
    return `Bearer ${token}`;
  }
  return token;
};

export const getIsUserAuthenticated = () => {
  const getBearerToken = getToken(bearerToken, false);
  const isValid = isValidBearer(getBearerToken);
  return isValid ? getBearerToken.length > 0 : false;
};

export const getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: getToken(bearerToken),
});

export const isValidEmail = (value: string) => {
  const regex = new RegExp('^[^\\s@]+@([^\\s@.,]+\\.)+[^\\s@.,]{2,}$');
  return regex.test(value);
};
