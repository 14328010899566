import { Shape } from '../types';

export const buildQuery = (query: Shape): string => {
  const validParameters: Shape = {};
  Object.keys(query).forEach((key) => {
    if (query[key] === '' || query[key] === undefined) {
      return;
    }

    validParameters[key] = query[key];
  });

  if (Object.keys(validParameters).length > 0) {
    const params = new URLSearchParams(validParameters);
    return `?${params.toString()}`;
  }
  return '';
};
