import { createSize } from './sizes';

export const SPACING_0 = 0;
export const SPACING_1 = createSize(4);
export const SPACING_2 = createSize(8);
export const SPACING_3 = createSize(12);
export const SPACING_4 = createSize(16);
export const SPACING_5 = createSize(20);
export const SPACING_6 = createSize(24);
export const SPACING_7 = createSize(28);
export const SPACING_8 = createSize(32);
export const SPACING_9 = createSize(36);
export const SPACING_10 = createSize(40);
export const SPACING_11 = createSize(44);
export const SPACING_12 = createSize(48);
export const SPACING_13 = createSize(52);
export const SPACING_14 = createSize(56);
export const SPACING_15 = createSize(60);
export const SPACING_16 = createSize(64);
export const SPACING_18 = createSize(72);
export const SPACING_20 = createSize(80);
export const SPACING_30 = createSize(120);
export const SPACING_40 = createSize(160);
