import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiRecipePaginated } from '../constants/api';
import { getHeaders } from '../helpers/auth';
import { IRecipesQueryParameters, IRecipesReducerState } from '../types';

const initialState: IRecipesReducerState = {
  response: {
    metadata: {
      page: 1,
      onPage: 0,
      pagesCount: 0,
      totalElements: 0,
    },
    data: [],
  },
  query: {
    page: 1,
  },
};

export const updateRecipesQuery = createAsyncThunk<
  IRecipesQueryParameters,
  IRecipesQueryParameters
>('recipes/updateQuery', (query) => query);

export const getRecipes = createAsyncThunk<
  IRecipesQueryParameters,
  IRecipesReducerState
>(
  'recipes/get',
  // @ts-ignore
  async (query: IRecipesQueryParameters, thunkAPI) => {
    try {
      const response: any = await fetch(apiRecipePaginated(query), {
        method: 'GET',
        headers: getHeaders(),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getRecipes.fulfilled}`]: (state, action) => {
      return { response: action.payload, query: state.query };
    },
    [`${getRecipes.rejected}`]: (state) => {
      return { response: initialState.response, query: state.query };
    },
    [`${updateRecipesQuery.fulfilled}`]: (state, action) => {
      return { response: state.response, query: action.payload };
    },
  },
});

export default recipesSlice.reducer;
