import React from 'react';
import styled from 'styled-components';

import CrackedEgg from '../../assets/images/cracked-egg.png';

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 15px 0 10px 0;
`;

const TextWrapper = styled.div`
  font-weight: bold;
`;

const ImageWrapper = styled.div`
  img {
    height: 100px;
  }
`;

const NoResults: React.FC = () => {
  return (
    <NoResultsWrapper>
      <TextWrapper>No results, sorry!</TextWrapper>
      <ImageWrapper>
        <img src={CrackedEgg} alt="no results icon" />
      </ImageWrapper>
    </NoResultsWrapper>
  );
};

export default NoResults;
