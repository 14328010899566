import { v4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from '../../assets';
import { FavouriteRecipe, Pagination, StickySidebar } from '../../components';
import NoResults from '../../components/NoResults';
import { errorDefault } from '../../constants/defaultTypes';
import { GROUP_5, SPACING_4 } from '../../foundations';
import { fetchFavouriteRecipes } from '../../reducers/homepageReducer';
import { fetchFavouriteRecipeIds } from '../../reducers/profileReducer';
import { getRecipes, updateRecipesQuery } from '../../reducers/recipesReducer';
import { selectProfileFavouriteIds } from '../../selectors/profileSelector';
import {
  selectRecipesData,
  selectRecipesMetadata,
  selectRecipesQuery,
} from '../../selectors/recipesSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { IError, IViewRecipe, Shape } from '../../types';
import { IRecipeSearchParams } from './types';

const RecipeList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
`;

const RecipeWrapper = styled.li`
  list-style-type: none;
  padding-bottom: ${SPACING_4};

  @media (min-width: ${GROUP_5}) {
    margin-right: ${SPACING_4};
  }
`;

const extractSearchParams = (
  searchParams: URLSearchParams,
): IRecipeSearchParams => {
  const parsed: IRecipeSearchParams = {};
  if (searchParams.has('phrase')) {
    parsed['phrase'] = searchParams.get('phrase') ?? '';
  }

  if (searchParams.has('categoryName')) {
    parsed['categoryName'] = searchParams.get('categoryName') ?? '';
  }

  if (searchParams.has('page')) {
    parsed['page'] = parseInt(searchParams.get('page') ?? '');
  }

  return parsed;
};

const Recipes: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useSelector((state: RootState) => selectRecipesData(state));
  const metadata = useSelector((state: RootState) =>
    selectRecipesMetadata(state),
  );
  const query = useSelector((state: RootState) => selectRecipesQuery(state));
  const [loading, setLoading] = useState(true);
  const favouriteIds = useSelector((state: RootState) =>
    selectProfileFavouriteIds(state),
  );
  const [isReadyToFetch, setReadyToFetch] = useState(false);
  const [error, setError] = useState<IError>(errorDefault);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParameters = (values: Shape): void => {
    const parsed: Shape = extractSearchParams(searchParams);

    Object.keys(values).map((key: any) => {
      const value = values[key];

      if (parsed[key] !== undefined && (value === null || value === '')) {
        delete parsed[key];
      } else if (value !== null) {
        parsed[key] = value;
      }
    });

    setSearchParams(parsed);
  };

  const handleFetchFavouriteIds = () => {
    dispatch(
      // @ts-ignore
      fetchFavouriteRecipeIds({}),
    );
  };

  useEffect(() => {
    const stringPage = searchParams.get('page');
    const page = stringPage !== null ? parseInt(stringPage) : 1;
    dispatch(
      // @ts-ignore
      updateRecipesQuery({
        ...query,
        categoryName: searchParams.get('categoryName') ?? undefined,
        phrase: searchParams.get('phrase') ?? undefined,
        page: page,
      }),
    );

    setReadyToFetch(true);
  }, [isReadyToFetch]);

  useEffect(() => {
    handleFetchFavouriteIds();
  }, [false]);

  const handleGetRecipes = () => {
    setLoading(true);
    dispatch(
      // @ts-ignore
      getRecipes({
        ...query,
        // @ts-ignore
        onPage: 12,
      }),
    )
      .unwrap()
      .catch((e: IError) => {
        console.log({ e });
        setError(e);
      });
    setLoading(false);
  };

  const handleGetFavourites = () => {
    const stringPage = searchParams.get('page');
    const page = stringPage !== null ? parseInt(stringPage) : 1;
    dispatch(fetchFavouriteRecipes({ onPage: 3, page: page }));
  };

  useEffect(() => {
    if (!isReadyToFetch) {
      return;
    }
    handleGetRecipes();
  }, [query]);

  return (
    <>
      <StickySidebar
        queryState={query}
        updateSearchParameters={updateSearchParameters}
      />

      {loading && <Loader />}
      {!loading && (
        <>
          {error.message && !data && <div>{error.message}</div>}
          {!loading && data && data.length === 0 && !error.message && (
            <NoResults />
          )}

          {data.length > 0 && (
            <>
              <RecipeList>
                {data.map((recipe: IViewRecipe) => (
                  <RecipeWrapper key={v4()}>
                    <FavouriteRecipe
                      recipe={recipe}
                      handleGetFavourites={handleGetFavourites}
                      isFavourite={favouriteIds.indexOf(recipe.id) !== -1}
                    />
                  </RecipeWrapper>
                ))}
              </RecipeList>
              <Pagination
                page={metadata.page}
                pagesCount={metadata.pagesCount}
                setPage={(page: number) => {
                  dispatch(
                    // @ts-ignore
                    updateRecipesQuery({
                      ...query,
                      page,
                    }),
                  );
                  updateSearchParameters({
                    page: page !== 1 ? page : null,
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Recipes;
