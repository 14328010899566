import {
  IAddIngredient,
  IIngredientTypeReplaceSingleValue,
  IRecipeIngredient,
} from '../types';

export const generateAlphabet = () => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  return alpha.map((x) => String.fromCharCode(x));
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const replaceRecipeIngredientsItem = (
  obj: {
    amount?: number;
    measurement?: string;
    skipAmount?: boolean;
    preparationInstructions?: string;
    skipAmountDescription?: string;
    group?: string;
    ingredient?: string;
    name?: string;
    type?: string;
  },
  array: IRecipeIngredient[],
  i: number,
) => {
  return [
    ...array.slice(0, i),
    {
      ...array[i],
      ...obj,
    },
    ...array.slice(i + 1),
  ];
};

export const replaceIngredientValue = (
  obj: IIngredientTypeReplaceSingleValue,
  array: IAddIngredient[],
  i: number,
) => {
  return [
    ...array.slice(0, i),
    {
      ...array[i],
      ...obj,
    },
    ...array.slice(i + 1),
  ];
};
