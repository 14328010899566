import isHotkey from 'is-hotkey';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import React, { useCallback, useMemo } from 'react';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import { Element, Leaf, MarkButton, Toolbar } from './components';
import BlockButton from './components/BlockButton';
import { HOTKEYS, toggleMark } from './helpers';

const RichTextEditor: React.FC<{
  value: Descendant[];
  onChange: (e: Descendant[]) => void;
}> = ({ value, onChange }) => {
  // @ts-ignore
  // eslint-disable-next-line react/jsx-no-undef
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  // @ts-ignore
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <Toolbar>
        <MarkButton format="bold" icon={<FormatBoldIcon />} />
        <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
        <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter some instructions..."
        spellCheck
        autoFocus
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

export default RichTextEditor;
