import { isHexColour } from './common';
import {
  calculateContrast,
  convertToHsl,
  hexToHsl,
  hexToRgb,
  hslToHex,
  rgbToHsl,
} from './conversion';

const findClosestAccessibleLighterColour = (
  adjustableColour: string,
  otherColour: number[],
  contrastRatio: number,
) => {
  const hsl = hexToHsl(adjustableColour);
  const h = hsl[0];
  const s = hsl[1];
  let l = hsl[2];

  // @ts-ignore
  if (calculateContrast(adjustableColour, otherColour) >= contrastRatio) {
    return {
      colour: adjustableColour,
      lightness: l,
    };
  }

  let maxColour = hslToHex([h, s, 100]);
  // @ts-ignore
  if (calculateContrast(maxColour, otherColour) < contrastRatio) {
    return null;
  }

  let min = l;
  let max = 100;
  let minColour = hslToHex([h, s, l]);
  let lastMinColour;
  let lastMaxColour;
  let calculatedAdjustableColour = adjustableColour;

  while (minColour !== lastMinColour || maxColour !== lastMaxColour) {
    lastMinColour = minColour;
    lastMaxColour = maxColour;

    l = (min + max) / 2;
    calculatedAdjustableColour = hslToHex([h, s, l]);
    if (
      // @ts-ignore
      calculateContrast(calculatedAdjustableColour, otherColour) < contrastRatio
    ) {
      min = l;
      minColour = hslToHex([h, s, l]);
    } else {
      max = l;
      maxColour = hslToHex([h, s, l]);
    }
  }

  return {
    colour: maxColour,
    lightness: max,
  };
};

const findClosestAccessibleDarkerColour = (
  adjustableColour: string,
  otherColour: number[],
  contrastRatio: number,
) => {
  const hsl = hexToHsl(adjustableColour);
  const h = hsl[0];
  const s = hsl[1];
  let l = hsl[2];
  // @ts-ignore
  if (calculateContrast(adjustableColour, otherColour) >= contrastRatio) {
    return {
      colour: adjustableColour,
      lightness: l,
    };
  }

  let minColour = hslToHex([h, s, 0]);
  // @ts-ignore
  if (calculateContrast(minColour, otherColour) < contrastRatio) {
    return null;
  }

  let min = 0;
  let max = l;
  let maxColour = hslToHex([h, s, l]);
  let lastMinColour;
  let lastMaxColour;
  let calculatedAdjustableColour = adjustableColour;

  while (minColour !== lastMinColour || maxColour !== lastMaxColour) {
    lastMinColour = minColour;
    lastMaxColour = maxColour;

    l = (min + max) / 2;
    calculatedAdjustableColour = hslToHex([h, s, l]);
    if (
      // @ts-ignore
      calculateContrast(calculatedAdjustableColour, otherColour) < contrastRatio
    ) {
      max = l;
      maxColour = hslToHex([h, s, l]);
    } else {
      min = l;
      minColour = hslToHex([h, s, l]);
    }
  }

  return {
    colour: minColour,
    lightness: min,
  };
};

export const findClosestAccessibleColour = (
  backgroundColour: string,
  textColour: number[],
  selectedRatio: number,
) => {
  const closestLighterColour = findClosestAccessibleLighterColour(
    backgroundColour,
    textColour,
    selectedRatio,
  );
  const closestDarkerColour = findClosestAccessibleDarkerColour(
    backgroundColour,
    textColour,
    selectedRatio,
  );

  if (closestDarkerColour === null) {
    if (closestLighterColour === null) {
      return null;
    }

    return closestLighterColour.colour;
  }

  if (closestLighterColour === null) {
    return closestDarkerColour.colour;
  }

  const colourLightness = convertToHsl(backgroundColour)[2];

  if (
    closestLighterColour.lightness - colourLightness <
    colourLightness - closestDarkerColour.lightness
  ) {
    return closestLighterColour.colour;
  }

  return closestDarkerColour.colour;
};

export const calculateContrastRatio = (
  backgroundColour: number[],
  textColour: number[],
): number =>
  Math.round(100 * calculateContrast(backgroundColour, textColour)) / 100;

export const pickAccessibleColours = (
  backgroundColour: number[],
  textColour: number[],
  selectedRatio: number = 4.5,
) => {
  const ratio = calculateContrastRatio(backgroundColour, textColour);
  if (ratio >= selectedRatio) {
    return {
      backgroundColour,
      textColour,
    };
  }
  const newBackgroundColour = findClosestAccessibleColour(
    // @ts-ignore
    backgroundColour,
    textColour,
    selectedRatio,
  );
  return {
    backgroundColour: newBackgroundColour,
    textColour,
  };
};

export const isColourLight = (colour: string | number[]) => {
  const isRGBColourLight = (rgbColour: string | number[]) =>
    // @ts-ignore
    Math.floor(rgbToHsl(rgbColour)[2]) > 50;

  // @ts-ignore
  return isHexColour(colour)
    ? // @ts-ignore
      isRGBColourLight(hexToRgb(colour))
    : isRGBColourLight(colour);
};
