import { v4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FavouriteRecipe } from '../../components';
import NoResults from '../../components/NoResults';
import { GROUP_5, SPACING_4 } from '../../foundations';
import { fetchFavouriteRecipes } from '../../reducers/homepageReducer';
import { selectHomepageFavouriteRecipes } from '../../selectors/homepageSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { IViewRecipe } from '../../types';

const SectionWrapper = styled.section``;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h4`
  position: relative;
  font-size: 26px;
  font-weight: 700;
  padding: ${SPACING_4} 0;
`;

const FavouriteRecipesWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const FavouriteRecipeWrapper = styled.li`
  list-style-type: none;
  padding-bottom: ${SPACING_4};

  @media (min-width: ${GROUP_5}) {
    margin-right: ${SPACING_4};
  }
`;

const Favourites: React.FC = () => {
  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(1);
  const [page, _] = useState(1);
  const favouriteRecipes = useSelector((state: RootState) =>
    selectHomepageFavouriteRecipes(state),
  );
  const dispatch = useAppDispatch();

  const handleGetFavourites = () => {
    dispatch(fetchFavouriteRecipes({ onPage: 12, page: page }));
  };

  useEffect(() => {
    setLoading(true);
    handleGetFavourites();
    setLoading(false);
  }, [page]);

  return (
    <>
      {!loading && favouriteRecipes?.length === 0 && <NoResults />}
      {!loading && favouriteRecipes?.length > 0 && (
        <SectionWrapper>
          <TitleWrapper>
            <Title>Your favourites</Title>
          </TitleWrapper>
          <FavouriteRecipesWrapper>
            {favouriteRecipes.map((recipe: IViewRecipe) => (
              <FavouriteRecipeWrapper key={v4()}>
                <FavouriteRecipe
                  recipe={recipe}
                  handleGetFavourites={handleGetFavourites}
                  isFavourite
                />
              </FavouriteRecipeWrapper>
            ))}
          </FavouriteRecipesWrapper>

          {/*<Pagination*/}
          {/*  page={page}*/}
          {/*  pagesCount={10}*/}
          {/*  setPage={(page: number) => {*/}
          {/*    setPage(page)*/}
          {/*  }}*/}
          {/*/>*/}
        </SectionWrapper>
      )}
    </>
  );
};

export default Favourites;
