import React from 'react';
import styled from 'styled-components';

import { Pagination } from '@mui/material';

import { SPACING_4, SPACING_6 } from '../../foundations';

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${SPACING_4} 0 ${SPACING_6} 0;
`;

interface IPaginationProps {
  pagesCount: number;
  page: number;
  setPage: (page: number) => void;
}

const PaginationComponent: React.FC<IPaginationProps> = ({
  pagesCount,
  setPage,
  page,
}) => {
  return (
    <PaginationWrapper>
      <Pagination
        count={pagesCount}
        page={page}
        shape="rounded"
        showFirstButton
        showLastButton
        onChange={(_, page) => setPage(page)}
      />
    </PaginationWrapper>
  );
};

export default PaginationComponent;
