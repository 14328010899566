import React from 'react';
import styled from 'styled-components';

import { SPACING_1, SPACING_2, createSize } from '../../foundations';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: ${SPACING_1} ${SPACING_2};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 5px 0 rgba(34, 34, 34, 0.2);
  min-width: ${createSize(250)};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid #e1e2df;
`;

const Card: React.FC<{
  children: React.ReactNode;
  buttons?: React.ReactNode;
}> = ({ children, buttons }) => {
  return (
    <ContentWrapper>
      <DataWrapper>{children}</DataWrapper>
      <ButtonsWrapper>{buttons}</ButtonsWrapper>
    </ContentWrapper>
  );
};

export default Card;
