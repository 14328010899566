import React, { useState } from 'react';
import styled from 'styled-components';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Alert, FormControl, IconButton } from '@mui/material';

import { apiGenerateImagePreflight } from '../../constants/api';
import { errorDefault } from '../../constants/defaultTypes';
import { getHeaders } from '../../helpers/auth';
import { IError, IGenerateUrlResponse } from '../../types';

const Input = styled.input`
  display: none;
`;

const UploadImage: React.FC<{
  setImage: (value: string | undefined) => void;
}> = ({ setImage }) => {
  const [imageUrls, setImageUrls] = useState<IGenerateUrlResponse>({
    accessUrl: '',
    uploadUrl: '',
  });

  const [generateUrlGetError, setGenerateUrlGetError] =
    useState<IError>(errorDefault);

  const getUrl = () => {
    fetch(apiGenerateImagePreflight, {
      method: 'POST',
      headers: getHeaders(),
    })
      .then((res) => res.json())
      .then((data: IGenerateUrlResponse) => {
        setGenerateUrlGetError(errorDefault);
        setImageUrls(data);
      })
      .catch((error) => {
        setGenerateUrlGetError(error);
        setImageUrls({});
      });
  };

  const onSelectFile = (event: any) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const selectedFile = files[0];
    fetch(`${imageUrls.uploadUrl}`, {
      method: 'PUT',
      headers: {
        'Content-Type': selectedFile.type,
      },
      body: selectedFile,
    })
      .then(() => setImage(imageUrls.accessUrl))
      .catch((error) => setGenerateUrlGetError(error));
  };

  return (
    <FormControl fullWidth>
      <label htmlFor="icon-button-file">
        <Input
          accept="image/*"
          id="icon-button-file"
          type="file"
          onClick={() => getUrl()}
          onChange={(event) => onSelectFile(event)}
        />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
      </label>

      {generateUrlGetError?.message && (
        <Alert severity="error">{generateUrlGetError.message}</Alert>
      )}
    </FormControl>
  );
};

export default UploadImage;
