import React from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, IconButton } from '@mui/material';

import { Loader } from '../../assets';

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  max-width: 500px;
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const Body = styled.div<{ isSuccess: boolean; messageBarTiming: number }>`
  position: relative;
  padding: 16px;
  ${({ isSuccess, messageBarTiming }) =>
    isSuccess &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: green;
        animation: messageBarProgress ${messageBarTiming}ms linear infinite;

        @keyframes messageBarProgress {
          0% {
            width: 100%;
          }
          100% {
            width: 0%;
          }
        }
      }
    `};
`;

const ChildrenWrapper = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

const Footer = styled.div<{ isAdditionalButton: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: ${({ isAdditionalButton }) =>
    isAdditionalButton ? 'space-between' : 'flex-end'};
  padding: 12px;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const Title = styled.h5`
  margin-bottom: 0;
  line-height: 1.6;
  font-size: 20px;
`;

const LoadingWrapper = styled.div<{ isError?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  color: ${({ isError }) => (isError ? 'red' : '#fff')};
`;

interface IModalProps {
  title: string;
  onClose: () => void;
  onSave: (a: any) => void;
  onCloseText?: string;
  onSaveText?: string;
  children: React.ReactNode;
  disableSaveButton?: boolean;
  disableCloseButton?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSuccess?: boolean;
  successMessage?: string;
  additionalButtonText?: string;
  additionalButtonOnClick: (a: any) => void;
  disableAdditionalButton?: boolean;
  messageBarTiming?: number;
}

const Modal: React.FC<IModalProps> = ({
  title,
  onClose,
  onSave,
  onCloseText = 'Close',
  onSaveText = 'Save',
  disableSaveButton = false,
  disableCloseButton = false,
  children,
  isLoading = false,
  isError = false,
  errorMessage = 'Something went wrong! Sorry :(',
  additionalButtonText,
  additionalButtonOnClick,
  disableAdditionalButton = false,
  isSuccess = false,
  successMessage = 'Success!',
  messageBarTiming = 5000,
}) => {
  const isAdditionalButton = Boolean(
    additionalButtonText && additionalButtonOnClick,
  );
  return (
    <ModalWrapper>
      <Header>
        <Title>{title}</Title>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={disableCloseButton}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <Body isSuccess={isSuccess} messageBarTiming={messageBarTiming}>
        {isLoading && (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        )}
        {!isSuccess && <ChildrenWrapper>{children}</ChildrenWrapper>}
        {isError && <Alert severity="error">{errorMessage}</Alert>}
        {isSuccess && <Alert severity="success">{successMessage}</Alert>}
      </Body>
      <Footer isAdditionalButton={isAdditionalButton}>
        {isAdditionalButton && (
          <Button
            variant="text"
            disabled={disableAdditionalButton}
            onClick={additionalButtonOnClick}
          >
            {additionalButtonText}
          </Button>
        )}
        <div>
          <Button
            variant="text"
            onClick={onClose}
            disabled={disableCloseButton}
          >
            {onCloseText}
          </Button>
          <Button
            variant="contained"
            disabled={disableSaveButton}
            onClick={onSave}
          >
            {onSaveText}
          </Button>
        </div>
      </Footer>
    </ModalWrapper>
  );
};

export default Modal;
