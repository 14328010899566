import throttle from 'lodash/throttle';

import React, { useEffect, useMemo, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';

import {
  addRecipeIngredient,
  setRecipeIngredients,
} from '../../../reducers/ingredientsGroupsReducer';
import { getIngredients } from '../../../reducers/ingredientsReducer';
import { useAppDispatch } from '../../../store/store';
import { IIngredientsGroup, IRecipeIngredient } from '../../../types';

const IngredientsSearch: React.FC<{
  options: IRecipeIngredient[];
  group: IIngredientsGroup;
}> = ({ options, group }) => {
  const [isOpen, setIsOpen] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const loading = isOpen && options.length === 0;
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = React.useState('');

  const fetch = useMemo(
    () =>
      throttle(async (request: { input: string }) => {
        dispatch(getIngredients({ search: request.input }));
      }, 500),
    [],
  );

  useEffect(() => {
    fetch({ input: inputValue });
  }, [inputValue, fetch]);

  const addAdditionalIngredient = (ingredient: IRecipeIngredient) => {
    dispatch(
      addRecipeIngredient({
        groupId: group.id,
        recipeIngredient: ingredient,
      }),
    );
  };

  const setNewGroupsIngredients = (ingredients: IRecipeIngredient[]) => {
    dispatch(
      setRecipeIngredients({
        groupId: group.id,
        recipeIngredients: ingredients,
      }),
    );
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        freeSolo
        multiple
        disableCloseOnSelect
        open={isOpen}
        onChange={(e, newValue: any[], reason) => {
          if (reason === 'selectOption') {
            addAdditionalIngredient(newValue[newValue.length - 1]);
          }
          if (reason === 'removeOption') {
            setNewGroupsIngredients(newValue);
          }
        }}
        value={Object.keys(group.ingredients).map(
          (key) => group.ingredients[key],
        )}
        onOpen={() => {
          setIsOpen(true);
        }}
        filterOptions={(x) => x}
        clearOnEscape
        autoSelect={true}
        onClose={() => {
          setIsOpen(false);
          setInputValue('');
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        loading={loading}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Ingredient"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default IngredientsSearch;
