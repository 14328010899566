import React from 'react';
import styled from 'styled-components';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

import { createSize } from '../../foundations';

const PreviewWrapper = styled.div`
  display: flex;
`;

const ImagePreview = styled.img`
  height: ${createSize(50)};
  width: fit-content;
`;

const DisplayImage: React.FC<{
  isRemovable?: boolean;
  imageUrl: string;
  removeImage?: () => void;
}> = ({ isRemovable = false, removeImage, imageUrl }) => {
  return (
    <PreviewWrapper>
      <ImagePreview src={imageUrl} />
      {isRemovable && (
        <IconButton onClick={removeImage}>
          <DeleteIcon />
        </IconButton>
      )}
    </PreviewWrapper>
  );
};

export default DisplayImage;
