import { createSize } from './sizes';

export const GROUP_1_INT = 0;
export const GROUP_2_INT = 400;
export const GROUP_3_INT = 600;
export const GROUP_4_INT = 900;
export const GROUP_5_INT = 1008;
export const GROUP_6_INT = 1280;
export const GROUP_7_INT = 1440;

export const GROUP_1 = createSize(GROUP_1_INT);
export const GROUP_2 = createSize(GROUP_2_INT);
export const GROUP_3 = createSize(GROUP_3_INT);
export const GROUP_4 = createSize(GROUP_4_INT);
export const GROUP_5 = createSize(GROUP_5_INT);
export const GROUP_6 = createSize(GROUP_6_INT);
export const GROUP_7 = createSize(GROUP_7_INT);
